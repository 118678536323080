import {Button} from "@mui/material";
import React, {useCallback, useContext, useState} from "react";
import {useCreateNextPayStubMutation} from "../../graphql/generated/graphql";
import {useNavigate} from "react-router-dom";
import useSystemNotices from "../../Utils/useSystemNotices";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import {assumedUserContext} from "../User/AssumedUserContext";
import YesOrNoDialog from "../../common/YesOrNoDialog";

type PropsType = {
    previousPayStubId: number;
    payDate: string;
}

const CreateNextPayStubButton = (props: PropsType) => {

    const {previousPayStubId, payDate} = props;
    const navigate = useNavigate();
    const {sendNotice} = useSystemNotices();
    const {assumedUser} = useContext(assumedUserContext);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);

    const [error, setError] = useState<string>();

    const [
        createNextPayStub,
        {
            loading: createNextPayStubLoading,
        }
    ] = useCreateNextPayStubMutation();

    const createNextPayStubNow = useCallback(() => {
        if (createNextPayStubLoading) {
            return;
        }
        if (!assumedUser) {
            return;
        }
        createNextPayStub({
            variables: {
                previousPayStubId: previousPayStubId,
                userId: assumedUser.id
            }
        })
            .then(result => {
                let nextPayStub = result.data?.createNextPayStub;
                if (!!nextPayStub?.successful && nextPayStub.payStubWithDeductions) {
                    navigate(`/pay-stub/${nextPayStub.payStubWithDeductions.payStub.id}`);
                } else if (!!nextPayStub?.message) {
                    sendNotice(`Error creating next pay stub for previous pay stub ID: ${previousPayStubId}. Error: ${nextPayStub.message}`);
                    setError(nextPayStub.message);
                } else {
                    sendNotice(`Error creating next pay stub for previous pay stub ID: ${previousPayStubId}. Error: Unknown error. This should not be possible.`);
                    setError("Unknown error. Please try again or contact support.");
                }
            })
            .catch(error => {
                sendNotice(`Error creating next pay stub for previous pay stub ID: ${previousPayStubId}. Error: ${error.message}`);
                setError("Error creating next pay stub. Please try again or contact support.");
            });

    }, [assumedUser, createNextPayStub, createNextPayStubLoading, navigate, previousPayStubId, sendNotice]);


    const createNextPayStubStatement = `This will create a new pay stub using pay stub with ID: ${previousPayStubId} and pay date: ${payDate} 
        as the previous pay stub. YTD values
        in the new pay stub will be automatically calculated based on the previous pay stub. Dates and current period
        amounts will also be generated but should be reviewed to ensure they accurately reflect the pay period.`


    return <>
        <Button
            onClick={() => setConfirmDialogOpen(true)}
            variant={"contained"}
        >
            Create next pay stub
        </Button>
        <LoadingErrorDisplay
            loading={createNextPayStubLoading}
            stringError={error}
        />
        {confirmDialogOpen &&
            <YesOrNoDialog
                handleBooleanAnswer={isYes => {
                    setConfirmDialogOpen(false);
                    if (isYes) {
                        createNextPayStubNow();
                    }
                }}
                yesLabel={"Continue"}
                noLabel={"Cancel"}
                question={createNextPayStubStatement}
                cancel={() => setConfirmDialogOpen(false)}
            />
        }
    </>

}
export default CreateNextPayStubButton;