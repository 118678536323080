import {useCalculateVacationSummaryMutation} from "../../graphql/generated/graphql";
import {useQuery} from "@apollo/client";
import GET_VACATION_SUMMARY from "../../graphql/queries/getVacationSummary";
import {useCallback} from "react";
import useAnonCookie from "../../security/useAnonCookie";
import usePayStubIdManager from "./usePayStubIdManager";
import useSystemNotices from "../../Utils/useSystemNotices";


type ReturnType = {
    recalculateVacationSummary: () => void;
}

const useCalculateVacationSummary = (): ReturnType => {

    const {getAnonUserId} = useAnonCookie();
    const anonUserId = getAnonUserId();
    const {getPayStubId} = usePayStubIdManager();
    const payStubId = getPayStubId();
    const {sendNotice} = useSystemNotices();

    const [calculateVacationSummary] = useCalculateVacationSummaryMutation();
    const {refetch: refetchVacationSummary} = useQuery(GET_VACATION_SUMMARY, {
        variables: {
            anonUserId: anonUserId,
            payStubId: payStubId
        },
        skip: !payStubId,
    });

    const recalculateVacationSummary = useCallback(() => {
        if (!payStubId) {
            sendNotice("Unable to recalculate vacation summary. No pay stub id found.");
            return;
        }
        calculateVacationSummary({
            variables: {
                anonUserId: anonUserId,
                payStubId: payStubId
            }
        })
            .then(result => {
                if (result.data?.calculateVacationSummary.successful) {
                    refetchVacationSummary()
                        .catch(error => {
                            sendNotice(`Unable to refetch vacation summary. Pay stub id: ${payStubId}. Error: ${error.message}`);
                        })
                } else {
                    sendNotice(`Unable to recalculate vacation summary. Pay stub id: ${payStubId}. Error: ${result.data?.calculateVacationSummary.message}`);
                }
            })
            .catch(error => {
                sendNotice(`Unable to recalculate vacation summary. Pay stub id: ${payStubId}. Error: ${error.message}`);
            });
    }, [anonUserId, calculateVacationSummary, payStubId, refetchVacationSummary, sendNotice]);

    return {
        recalculateVacationSummary
    };
}

export default useCalculateVacationSummary;