import {GetUserActivationsQuery, useGetUserActivationsQuery} from "../graphql/generated/graphql";
import React, {useCallback, useContext} from "react";
import ErrorDisplay from "../common/ErrorDisplay";
import {DataGrid, GridCellParams} from "@mui/x-data-grid";
import {Button, Typography, useMediaQuery} from "@mui/material";
import {assumedUserContext} from "../components/User/AssumedUserContext";
import {useNavigate} from "react-router-dom";

type UserActivationRow = {
    id: number | null | undefined;
    firstName: string | null | undefined;
    lastName: string | null | undefined;
    email: string;
    mobile: string | null | undefined;
    subscriptionStatus?: string | undefined | null;
    firstEmployer: string | null | undefined;
    numEmployers: number | null | undefined;
    firstEmployee: string | null | undefined;
    numEmployees: number | null | undefined;
    numPayStubs: number | null | undefined;
    numPayStubsCompleted: number | null | undefined;
    numPayStubsFinalized: number | null | undefined;
    numPayStubsWithYtd: number | null | undefined;
    activationStatus?: string | undefined | null;
    subscriptionEmployeeCount?: number | undefined | null;
    createdDate: string | null | undefined;
}

interface MyGridCellParams extends GridCellParams {
    row: UserActivationRow;
}

function getActivationCellFontColour(params: MyGridCellParams) {
    switch (params.row.activationStatus) {
        case 'GREEN':
            return 'green';
        case 'YELLOW':
            return 'orange';
        case 'RED':
            return 'red';
        default:
            return 'black';
    }
}

function getSubscriptionDescription(params: MyGridCellParams) {
    if (!params.row.subscriptionEmployeeCount && params.row.subscriptionStatus === 'NONE') {
        return "";
    }
    return `${params.row.subscriptionEmployeeCount} - ${params.row.subscriptionStatus}`;
}

function getUserActivationColumns(setAssumedUser: (userId: number | null | undefined) => void, isSmallScreen: boolean) {
    const coreColumns = [

        {
            field: 'firstName',
            headerName: 'Name',
            flex: 1.2,
            cellClassName: 'cell',
            renderCell: (params: MyGridCellParams) => {
                return <Button
                    variant={'text'}
                    onClick={() => setAssumedUser(params.row.id)}
                >
                    {params.row.firstName} {params.row.lastName}
                </Button>
            }
        },

        {
            field: 'email', headerName: 'Email', flex: 1.4, cellClassName: 'cell',
            renderCell: (params: MyGridCellParams) => {
                return <Typography
                    style={{
                        color: getActivationCellFontColour(params),
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%"
                    }}
                >
                    {params.row.email}
                </Typography>;
            }
        },

        {
            field: 'subscriptionStatus',
            headerName: 'Subscription',
            flex: 0.7,
            cellClassName: 'cell',
            renderCell: (params: MyGridCellParams) => {
                return <Typography
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%"
                    }}
                >
                    {getSubscriptionDescription(params)}
                </Typography>;
            }
        },

        {
            field: 'numPayStubs',
            headerName: 'Pay stubs',
            flex: 0.7,
            cellClassName: 'cell',
        },

        {
            field: 'numPayStubsFinalized',
            headerName: 'Finalized',
            flex: 0.7,
            cellClassName: 'cell',
        },

        {field: 'createdDate', headerName: 'Created date', flex: 1, cellClassName: 'cell'},
    ];

    const wideScreenOnlyColumns = [
        {field: 'id', headerName: 'User ID', flex: 0.5, cellClassName: 'cell'},
        {field: 'mobile', headerName: 'Mobile', flex: 0.8, cellClassName: 'cell'},

        {
            field: 'numEmployers',
            headerName: 'Num employers',
            flex: 0.7,
            cellClassName: 'cell',
        },
        {field: 'firstEmployer', headerName: 'First employer', flex: 1.5, cellClassName: 'cell'},


        {
            field: 'numEmployees',
            headerName: 'Num employees',
            flex: 0.7,
            cellClassName: 'cell',
        },
        {field: 'firstEmployee', headerName: 'First employee', flex: 1.5, cellClassName: 'cell'},
        {
            field: 'numPayStubsCompleted',
            headerName: 'Num completed',
            flex: 0.7,
            cellClassName: 'cell',
        },
        {
            field: 'numPayStubsWithYtd',
            headerName: 'Num w/ YTD',
            flex: 0.7,
            cellClassName: 'cell',
        },
    ];

    return isSmallScreen ? coreColumns : [...coreColumns, ...wideScreenOnlyColumns];

}

function getRows(userActivationsData: GetUserActivationsQuery | undefined): UserActivationRow[] {
    if (!userActivationsData || !userActivationsData.getUserActivations || userActivationsData.getUserActivations.length === 0) {
        return [];
    }

    return userActivationsData.getUserActivations
        .map(userActivation => (
                {
                    id: userActivation.user.id,
                    firstName: userActivation.user.firstName,
                    lastName: userActivation.user.lastName,
                    email: userActivation.user.email,
                    mobile: userActivation.user.mobile,
                    subscriptionStatus: userActivation.subscriptionStatus,
                    firstEmployer: userActivation.firstEmployer,
                    numEmployers: userActivation.numEmployers,
                    firstEmployee: userActivation.firstEmployee,
                    numEmployees: userActivation.numEmployees,
                    numPayStubs: userActivation.numPayStubs,
                    numPayStubsCompleted: userActivation.numPayStubsCompleted,
                    numPayStubsFinalized: userActivation.numPayStubsFinalized,
                    numPayStubsWithYtd: userActivation.numPayStubsWithYtd,
                    activationStatus: userActivation.activationStatus,
                    subscriptionEmployeeCount: userActivation.subscriptionEmployeeCount,
                    createdDate: userActivation.user.createdDate,
                }
            )
        );
}

const CustomerHealth = () => {


    const {
        data: userActivationsData,
        error: userActivationsError,
        loading: userActivationsLoading,
    } = useGetUserActivationsQuery();

    const {setAssumedUser} = useContext(assumedUserContext);
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery('(max-width:900px)');

    const setUser = useCallback((userId: number | null | undefined) => {
        if (!userId) {
            console.error("tried to set assumed user without a user id.")
            return;
        }
        const userActivation = userActivationsData?.getUserActivations.find(userActivation => userActivation.user.id === userId);
        if (!userActivation) {
            console.error(`Could not find user for ID: ${userId}`);
            return;
        }
        setAssumedUser(userActivation.user);
        navigate("/employees");
    }, [navigate, setAssumedUser, userActivationsData?.getUserActivations]);

    if (userActivationsError) {
        return <ErrorDisplay
            apolloError={userActivationsError}
        />
    }

    return (
        <div style={{
            height: 900,
            width: '100%',
        }}>

            <DataGrid
                columns={getUserActivationColumns(setUser, isSmallScreen)}
                rows={getRows(userActivationsData)}
                initialState={{
                    sorting: {
                        sortModel: [{field: 'createdDate', sort: 'desc'}],
                    },
                }}
                loading={userActivationsLoading}
            />
        </div>
    )
}
export default CustomerHealth;