import React, {forwardRef} from "react";
import {TextField, TextFieldProps} from "@mui/material";


const RoeTextField = forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {


    return (
        <TextField
            {...props}
            InputLabelProps={{
                ...props.InputLabelProps,
                style: {fontSize: '1.4rem', ...props.InputLabelProps?.style},
                shrink: true,
            }}
            InputProps={{
                ...props.InputProps,
                style: {fontSize: '1.1rem', ...props.InputProps?.style},
            }}
            sx={{
                '& .MuiInputLabel-shrink': {
                    transform: 'translate(14px, -6px) scale(0.75)',
                },
                '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                    backgroundColor: 'white',
                    paddingRight: '5px',
                },
                ...props.sx,
            }}
            margin="normal"
            ref={ref}
        />
    );


});
export default RoeTextField;