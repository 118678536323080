import {
    InsurableEarnings,
    OtherMoniesInput,
    PayPeriod,
    PayPeriodInput,
    ReasonForIssuing,
    RoeExpectedRecallCode,
    RoeInitialValues,
    RoeReportParameterInput,
    RoeSeparationCode,
    SpecialPaymentsInput,
    StatHolidayPayInput,
    useGenerateRoeReportLazyQuery,
    VacationPay
} from "../../../graphql/generated/graphql";
import React, {useEffect, useRef, useState} from "react";
import LoadingErrorDisplay from "../../../common/LoadingErrorDisplay";
import RoeEmployeeEarningsForm from "./RoeEmployeeEarningsForm";
import {Box, Button, Grid} from "@mui/material";
import RoeEmployeeDetailsForm, {EmployeeDetailsType} from "./RoeEmployeeDetailsForm";
import {
    getRoeReportInputFromLocalStorageIfLessThan20SecondsOld,
    saveRoeReportInputToLocalStorage
} from "../../../Utils/localStorageManager";
import {ReasonForIssuingType} from "./RoeReasonForIssuing";
import {getNumber} from "../../../Utils/stringMath";

type PropsType = {
    close: () => void;
    roeInitialValues: RoeInitialValues;
}

function getDummyValueThatWillNotBeUsed(): PayPeriodInput[] {
    return [
        {
            amount: "0",
            number: 0,
            id: "0"
        }
    ]
}


function getReasonForIssuingFromRoeInitialValues(roeInitialValues: RoeInitialValues): ReasonForIssuingType {
    return {
        reasonCode: undefined, // need the user to explicitly choose a reason.
        contactAreaCode: roeInitialValues.contactAreaCode,
        contactFirstName: roeInitialValues.contactFirstName,
        contactLastName: roeInitialValues.contactLastName,
        contactPhone: roeInitialValues.contactPhone,
        contactExtension: ""
    };
}

function getDummySeparationCodeThatWillNotBeUsed() {
    return RoeSeparationCode.N00LeaveOfAbsence;
}

function getRoeReportParamInputDefaults(roeInitialValues: RoeInitialValues): RoeReportParameterInput {
    let reasonForIssuingFromRoeInitialValues = getReasonForIssuingFromRoeInitialValues(roeInitialValues);
    return {
        ...roeInitialValues,
        insurableEarnings: getDummyValueThatWillNotBeUsed(),
        employeeAddress: roeInitialValues.employeeAddress || "",
        employeeCity: roeInitialValues.employeeCity || "",
        employeeProvinceAndCountry: roeInitialValues.employeeProvinceAndCountry || "",
        employeePostalCode: roeInitialValues.employeePostalCode || "",
        socialInsuranceNumber: roeInitialValues.socialInsuranceNumber || "",
        expectedRecall: {
            expectedRecallCode: RoeExpectedRecallCode.Unknown,
        },
        payrollAccountNumber: "",
        reasonForIssuing: {
            ...reasonForIssuingFromRoeInitialValues,
            reasonCode: getDummySeparationCodeThatWillNotBeUsed() // this is ignored by the earnings form. Just need it here to satisfy the compiler. Yes, it's a bad smell. Probably should not be using the RoeReportParameterInput type to pass initial values down.
        },
        firstDayWorked: roeInitialValues.firstDayWorked || "",
        lastDayForWhichPaid: roeInitialValues.lastDayForWhichPaid || "",
    }
}

function getEmployeeDetails(roeReportInputs: RoeReportParameterInput | undefined): EmployeeDetailsType {
    return {
        employeeFirstName: roeReportInputs?.employeeFirstName || "",
        employeeMiddleInitial: roeReportInputs?.employeeMiddleInitial || "",
        employeeLastName: roeReportInputs?.employeeLastName || "",
        employeeAddress: roeReportInputs?.employeeAddress || "",
        employeeCity: roeReportInputs?.employeeCity || "",
        employeeProvinceAndCountry: roeReportInputs?.employeeProvinceAndCountry || "",
        employeePostalCode: roeReportInputs?.employeePostalCode || "",
        employeeOccupation: roeReportInputs?.employeeOccupation || "",
        socialInsuranceNumber: roeReportInputs?.socialInsuranceNumber || "",
    }
}

function getInsurableEarningsInput(insurableEarnings: PayPeriod[]): PayPeriodInput[] {
    return insurableEarnings.map(payPeriod => {
        return {
            amount: payPeriod.amount,
            number: payPeriod.number,
            id: payPeriod.id
        }
    });
}

function getInsurableEarningsFromRecentlySavedOrInitialValues(recentlySavedInputValues: RoeReportParameterInput | undefined, roeInitialValues: RoeInitialValues): InsurableEarnings {
    if (!!recentlySavedInputValues) {
        return {
            payPeriods: recentlySavedInputValues.insurableEarnings
        }
    }
    return roeInitialValues.insurableEarnings;
}

const RoeForm = (props: PropsType) => {

    const {roeInitialValues, close} = props;
    const recentlySavedInputValues = getRoeReportInputFromLocalStorageIfLessThan20SecondsOld(roeInitialValues.employeeId);
    const reasonForIssuingInitialValues = !!recentlySavedInputValues ? recentlySavedInputValues.reasonForIssuing : getReasonForIssuingFromRoeInitialValues(roeInitialValues);
    const insurableEarningsInitialValues = getInsurableEarningsFromRecentlySavedOrInitialValues(recentlySavedInputValues, roeInitialValues);
    const [validationErrors, setValidationErrors] = useState<string | undefined>(undefined);
    const [reportLink, setReportLink] = useState<string | undefined | null>(undefined);
    const [editingEmployeeDetails, setEditingEmployeeDetails] = useState<boolean>(true);
    const [editingEarnings, setEditingEarnings] = useState<boolean>(false);
    const [roeReportInputs, setRoeReportInputs]
        = useState<RoeReportParameterInput>(recentlySavedInputValues || getRoeReportParamInputDefaults(roeInitialValues));

    const errorRef = useRef<HTMLDivElement>(null);


    const [
        generateRoe,
        {
            loading: generateRoeLoading,
            error: generateRoeError
        }] = useGenerateRoeReportLazyQuery({
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        if (errorRef.current) {
            errorRef.current.scrollIntoView({behavior: "smooth"});
        }
    }, [generateRoeError, generateRoeLoading, validationErrors]);

    function generateRoeReport(values: RoeReportParameterInput,
                               insurableEarnings: PayPeriod[],
                               vacationPay: VacationPay | undefined | null,
                               statPay: StatHolidayPayInput[] | undefined | null,
                               otherMonies: OtherMoniesInput[] | undefined | null,
                               specialPayments: SpecialPaymentsInput[] | undefined | null,
                               reasonForIssuing: ReasonForIssuing
    ) {
        setValidationErrors(undefined);
        setReportLink(undefined);
        let roeInput = {
            insurableEarnings: getInsurableEarningsInput(insurableEarnings),
            vacationPay: vacationPay,
            statHolidayPay: statPay,
            otherMonies: otherMonies,
            specialPayments: specialPayments,
            reasonForIssuing: reasonForIssuing,
            employeeAddress: values.employeeAddress,
            employeeCity: values.employeeCity,
            employeeProvinceAndCountry: values.employeeProvinceAndCountry,
            employeeFirstName: values.employeeFirstName,
            employeeId: values.employeeId,
            employeeLastName: values.employeeLastName,
            employeePostalCode: values.employeePostalCode,
            expectedRecall: {
                expectedRecallCode: values.expectedRecall.expectedRecallCode,
                expectedRecallDate: values.expectedRecall.expectedRecallDate
            },
            finalPayPeriodEndDate: values.finalPayPeriodEndDate,
            firstDayWorked: values.firstDayWorked,
            lastDayForWhichPaid: values.lastDayForWhichPaid,
            payPeriodType: values.payPeriodType,
            payrollAccountNumber: values.payrollAccountNumber,
            socialInsuranceNumber: values.socialInsuranceNumber,
            employeeOccupation: values.employeeOccupation,
            submitDraft: values.submitDraft,
            totalInsurableHours: getNumber(values.totalInsurableHours),
            comment: values.comment
        };
        saveRoeReportInputToLocalStorage(roeInput, values.employeeId);
        generateRoe({
            variables: {
                params: {
                    ...roeInput,
                }
            }
        })
            .then(generateRoeData => {
                if (!!generateRoeData && generateRoeData.data?.generateRoeReport.message) {
                    setValidationErrors(generateRoeData.data?.generateRoeReport.message);
                } else if (!!generateRoeData && generateRoeData.data?.generateRoeReport.generated) {
                    setReportLink(generateRoeData.data.generateRoeReport.link);
                }
            });
    }

    const updateEmployeeDetails = (employeeDetails: EmployeeDetailsType) => {
        setRoeReportInputs(prevState => {
            return {
                ...prevState,
                ...employeeDetails,
            }
        });
        setEditingEmployeeDetails(false);
        setEditingEarnings(true);
    }

    const downloadButton = !!reportLink
        ? <Grid item xs={4}>
            <Box display="flex" justifyContent="flex-end">
                <a href={reportLink} download>
                    <Button
                        variant={"contained"}
                        style={{fontSize: '1.2rem', marginRight: '5rem', marginTop: '-12rem'}}
                    >
                        Download Report
                    </Button>
                </a>
            </Box>
        </Grid>
        :
        <></>;


    return <>
        {
            editingEmployeeDetails && (
                <RoeEmployeeDetailsForm
                    employeeDetails={getEmployeeDetails(roeReportInputs)}
                    close={close}
                    updateEmployeeDetails={updateEmployeeDetails}
                />
            )
        }
        {
            editingEarnings && (
                <RoeEmployeeEarningsForm
                    roeReportInputs={roeReportInputs}
                    reasonForIssuingInitialValues={reasonForIssuingInitialValues}
                    initialInsurableEarnings={insurableEarningsInitialValues}
                    generateRoe={generateRoeReport}
                    back={() => {
                        setEditingEarnings(false);
                        setEditingEmployeeDetails(true);
                    }}
                />
            )
        }
        {downloadButton}
        {
            (generateRoeError || generateRoeLoading || validationErrors) &&
            <LoadingErrorDisplay
                loading={generateRoeLoading}
                apolloError={generateRoeError}
                stringError={validationErrors}
                ref={errorRef}
            />
        }

    </>

}

export default RoeForm;