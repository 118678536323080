import {
    useDeleteVacationSummaryMutation,
    useSaveVacationSummaryMutation,
    VacationSummary,
    VacationSummaryInput
} from "../../graphql/generated/graphql";
import {useForm} from "react-hook-form";
import {Button, Card, CardContent, Grid2, Typography} from "@mui/material";
import OneTwoPayNumericTextField from "../../common/OneTwoPayNumericTextField";
import React, {useCallback, useEffect, useState} from "react";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import {makeStyles} from "@mui/styles";
import {add, getNumber, subtractStrings} from "../../Utils/stringMath";
import useSystemNotices from "../../Utils/useSystemNotices";
import useAnonCookie from "../../security/useAnonCookie";
import GET_VACATION_SUMMARY from "../../graphql/queries/getVacationSummary";
import ErrorDisplay from "../../common/ErrorDisplay";
import DeleteIcon from "@mui/icons-material/Delete";
import OneTwoPayIconWithTooltip from "../../common/OneTwoPayIconWithTooltip";


const useStyles = makeStyles(() => ({
    netCard: {
        boxShadow: '0 5px 10px rgba(0, 0, 0, 0.5)',
        borderRadius: '8px',
        height: '53px',
    },
    netCardContent: {
        paddingTop: '5px',
    },
    inputGridRow: {
        marginTop: '10px',
        marginBottom: '5px',
    },
    inputGrid: {}
}));

function calculateYtdAmount(currentPeriod: string, previousPeriod: string): string {
    return add(currentPeriod, previousPeriod);
}

function calculateNetAmount(earned: string, paid: string): string {
    return subtractStrings(earned, paid);
}


type PropsType = {
    vacationSummary: VacationSummary;
    close: () => void;
}

function getVacationSummaryInput(vacationSummary: VacationSummary): VacationSummaryInput {
    return {
        currentPeriodEarned: vacationSummary.currentPeriodEarned,
        currentPeriodUsedPaid: vacationSummary.currentPeriodUsedPaid,
        id: vacationSummary.id,
        payStubId: vacationSummary.payStubId,
        previousPeriodEarned: vacationSummary.previousPeriodEarned,
        previousPeriodUsedPaid: vacationSummary.previousPeriodUsedPaid,
        vacationAccrued: vacationSummary.vacationAccrued,
        ytdEarned: vacationSummary.ytdEarned,
        ytdUsedPaid: vacationSummary.ytdUsedPaid
    }
}

const VacationSummaryEdit = (props: PropsType) => {
    const {vacationSummary, close} = props;


    const classes = useStyles();
    const [previousPeriodsNet, setPreviousPeriodsNet] = useState<string>();
    const [currentPeriodNet, setCurrentPeriodNet] = useState<string>();
    const [error, setError] = useState<string | null | undefined>();
    const {sendNotice} = useSystemNotices();
    const {getAnonUserId} = useAnonCookie();
    const anonUserId = getAnonUserId();

    let getVacationSummaryQuery = {
        query: GET_VACATION_SUMMARY,
        variables: {
            anonUserId: anonUserId,
            payStubId: vacationSummary.payStubId,
        },
    };
    const [
        saveVacation,
        {
            loading: saveVacationLoading,
        }
    ] = useSaveVacationSummaryMutation({refetchQueries: [getVacationSummaryQuery]});

    const [
        deleteVacationSummary,
        {
            loading: deleteVacationSummaryLoading,
        }
    ] = useDeleteVacationSummaryMutation({refetchQueries: [getVacationSummaryQuery]});

    const {
        handleSubmit,
        register,
        formState: {errors},
        getValues,
        setValue,
        watch
    } = useForm<VacationSummaryInput>({
        defaultValues: getVacationSummaryInput(vacationSummary)
    });

    const saveVacationNow = useCallback((vacationSummaryInput: VacationSummaryInput) => {
        setError(null);
        if (saveVacationLoading) {
            return;
        }
        saveVacation({
            variables: {
                vacationSummaryInput: vacationSummaryInput,
                anonUserId: anonUserId
            },
        })
            .then(result => {
                if (!result.data?.saveVacationSummary.successful) {
                    const message = result.data?.saveVacationSummary.message;
                    console.error(error);
                    setError("Error trying to save vacation accrued. Please try again or contact support.");
                    sendNotice(`Error trying to save vacation accrued for pay stub ID: ${vacationSummaryInput.payStubId}. Message: ${message}`);
                    return;
                }
                close();
            })
            .catch(error => {
                console.error(error);
                setError("Error trying to save vacation accrued. Please try again or contact support.");
                sendNotice(`Error trying to save vacation accrued for pay stub ID: ${vacationSummaryInput.payStubId}. Message: ${error.message}`);
            });
    }, [saveVacationLoading, saveVacation, anonUserId, close, error, sendNotice]);

    const deleteVacationSummaryNow = useCallback(() => {
        setError(null);
        if (deleteVacationSummaryLoading) {
            return;
        }
        deleteVacationSummary({
            variables: {
                payStubId: vacationSummary.payStubId,
                anonUserId: anonUserId
            }
        })
            .then(result => {
                    let successful = result.data?.deleteVacationSummary.successful;
                    if (successful) {
                        close();
                    } else {
                        sendNotice(`Error deleting vacation summary for pay stub ID: ${vacationSummary.payStubId}. Error message: ${result.data?.deleteVacationSummary.message}`);
                        setError(`Error deleting vacation summary. Please try again or contact support.`);
                    }
                }
            )
            .catch(error => {
                sendNotice(`Error deleting vacation summary for pay stub ID: ${vacationSummary.payStubId}. Error message: ${error.message}`);
                setError(`Error deleting vacation summary. Please try again or contact support.`);
            });
    }, [anonUserId, close, deleteVacationSummary, deleteVacationSummaryLoading, sendNotice, vacationSummary.payStubId]);

    const [
        currentPeriodEarned,
        previousPeriodEarned,
        currentPeriodUsedPaid,
        previousPeriodUsedPaid,
        ytdEarned,
        ytdUsedPaid
    ] = watch(["currentPeriodEarned", "previousPeriodEarned", "currentPeriodUsedPaid", "previousPeriodUsedPaid", "ytdEarned", "ytdUsedPaid"]);

    useEffect(() => {
        setValue("ytdEarned", calculateYtdAmount(currentPeriodEarned, previousPeriodEarned));
        setValue("ytdUsedPaid", calculateYtdAmount(currentPeriodUsedPaid, previousPeriodUsedPaid));
        setPreviousPeriodsNet(calculateNetAmount(previousPeriodEarned, previousPeriodUsedPaid));
        setCurrentPeriodNet(calculateNetAmount(currentPeriodEarned, currentPeriodUsedPaid));
        setValue("vacationAccrued", calculateNetAmount(ytdEarned, ytdUsedPaid))
    }, [currentPeriodEarned, previousPeriodEarned, currentPeriodUsedPaid, previousPeriodUsedPaid, setValue, ytdEarned, ytdUsedPaid]);


    return <>
        {deleteVacationSummaryLoading
            ? <LoadingErrorDisplay
                loading={deleteVacationSummaryLoading}
            />
            : <OneTwoPayIconWithTooltip
                tipContent={'Delete vacation summary'}
                ariaLabel={'Delete vacation summary'}
                onClick={deleteVacationSummaryNow}
                tooltipSx={{ml: 2}}
                icon={<DeleteIcon sx={{fontSize: '2.5rem'}}/>}
                color={'error'}
            />
        }
        {
            (error) &&
            <ErrorDisplay
                stringError={error}
            />
        }

        <form noValidate onSubmit={handleSubmit(saveVacationNow)}>
            <Grid2 container direction={"column"}>
                <Grid2 container direction={"row"} className={classes.inputGridRow} spacing={2}>
                    <Grid2 size={{xs: 12, sm: 4}} className={classes.inputGrid}>
                        <OneTwoPayNumericTextField
                            label={"Earned in prior periods"}
                            amount={getValues("previousPeriodEarned")}
                            {...register("previousPeriodEarned")}
                            error={!!errors.previousPeriodEarned}
                            errorText={errors.previousPeriodEarned?.message}
                            variant={"outlined"}
                        />
                    </Grid2>

                    <Grid2 size={{xs: 12, sm: 4}} className={classes.inputGrid}>
                        <OneTwoPayNumericTextField
                            label={"Earned in current period"}
                            amount={getValues("currentPeriodEarned")}
                            {...register("currentPeriodEarned")}
                            error={!!errors.currentPeriodEarned}
                            errorText={errors.currentPeriodEarned?.message}
                            variant={"outlined"}
                        />
                    </Grid2>
                    <Grid2 size={{xs: 12, sm: 4}} className={classes.inputGrid}>
                        < Card className={classes.netCard}>
                            < CardContent className={classes.netCardContent}>
                                < Typography variant="subtitle1" color="text.secondary">
                                    Earned YTD
                                </Typography>
                                <Typography>
                                    ${getNumber(getValues("ytdEarned")).toFixed(2)}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid2>
                </Grid2>
                <Grid2 container direction={"row"} className={classes.inputGridRow} spacing={2}>
                    <Grid2 size={{xs: 12, sm: 4}} className={classes.inputGrid}>
                        < OneTwoPayNumericTextField
                            label={"Paid or used in prior periods"}
                            amount={getValues("previousPeriodUsedPaid")}
                            {...register("previousPeriodUsedPaid")}
                            error={!!errors.previousPeriodUsedPaid}
                            errorText={errors.previousPeriodUsedPaid?.message}
                            variant={"outlined"}
                        />
                    </Grid2>
                    <Grid2 size={{xs: 12, sm: 4}} className={classes.inputGrid}>
                        <OneTwoPayNumericTextField
                            label={"Paid or used in current period"}
                            amount={getValues("currentPeriodUsedPaid")}
                            {...register("currentPeriodUsedPaid")}
                            error={!!errors.currentPeriodUsedPaid}
                            errorText={errors.currentPeriodUsedPaid?.message}
                            variant={"outlined"}
                        />
                    </Grid2>
                    <Grid2 size={{xs: 12, sm: 4}} className={classes.inputGrid}>
                        <Card className={classes.netCard}>
                            <CardContent className={classes.netCardContent}>
                                <Typography variant="subtitle1" color="text.secondary">
                                    Paid or used YTD
                                </Typography>
                                <Typography>
                                    ${getNumber(getValues("ytdUsedPaid")).toFixed(2)}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid2>
                </Grid2>
                <Grid2 container direction={"row"} sx={{my: '15px'}} spacing={2}>
                    <Grid2 size={{xs: 12, sm: 4}}>
                        < Card className={classes.netCard}>
                            < CardContent className={classes.netCardContent}>
                                < Typography variant="subtitle1" color="text.secondary"> Prior periods
                                    net</Typography>
                                <Typography>
                                    ${getNumber(previousPeriodsNet).toFixed(2)}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid2>
                    <Grid2 size={{xs: 12, sm: 4}}>
                        < Card className={classes.netCard}>
                            < CardContent className={classes.netCardContent}>
                                < Typography variant="subtitle1" color="text.secondary"> Current period
                                    net</Typography>
                                <Typography>
                                    ${getNumber(currentPeriodNet).toFixed(2)}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid2>
                    <Grid2 size={{xs: 12, sm: 4}}>
                        < Card className={classes.netCard}>
                            < CardContent className={classes.netCardContent}>
                                < Typography sx={{
                                    fontSize: {xs: '1.2rem', lg: '1.5rem'},
                                    fontWeight: '500',
                                    color: 'text.primary'
                                }}>
                                    Vacation accrued (remaining)
                                </Typography>
                                <Typography>
                                    ${getNumber(getValues("vacationAccrued")).toFixed(2)}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid2>
                </Grid2>

                <Grid2 container direction="row" alignItems="center" justifyContent="space-between"
                       sx={{mt: "50px", mb: "100px"}}>
                    <Grid2 size={5}>
                        <Button
                            variant={"contained"}
                            style={{fontSize: '1.2rem'}}
                            fullWidth
                            color={"error"}
                            onClick={close}
                        >
                            Cancel
                        </Button>
                    </Grid2>
                    <Grid2 size={5}>
                        <Button
                            variant={"contained"}
                            fullWidth
                            type={"submit"}
                            style={{fontSize: '1.2rem'}}
                        >
                            Save
                        </Button>
                    </Grid2>
                </Grid2>
                <Grid2 size={12}>
                    <Typography
                        variant={"h6"}
                    >
                        Note that automated vacation accrual is optional. You can turn on / off automatic vacation
                        accrual in the employee settings.
                        Simply edit the employee and toggle the "Auto-accrue vacation" switch. When turned on, the
                        vacation pay percent given will be used
                        to add "Current period vacation earned" for each income item that is marked to be included.
                        Vacation pay income items will also be added to "Current period vacation used / paid".
                        Vacation summary numbers can always be manually edited here whether the switch is on or off.
                    </Typography>
                </Grid2>
            </Grid2>
        </form>
        <LoadingErrorDisplay
            loading={saveVacationLoading}
            stringError={error}
        />
    </>
}
export default VacationSummaryEdit;