import React, {useState} from "react";
import {
    InsurableEarnings,
    OtherMoniesInput,
    PayPeriod,
    ReasonForIssuing,
    RoeExpectedRecallCode,
    RoePayPeriodType,
    RoeReportParameterInput,
    RoeSeparationCode,
    SpecialPaymentsInput,
    StatHolidayPayInput,
    VacationPay
} from "../../../graphql/generated/graphql";
import {Box, Button, Divider, Grid, MenuItem, TextareaAutosize, TextField, Typography} from "@mui/material";
import {useForm} from "react-hook-form";
import ReactFormDatePicker from "../../../common/ReactHookFormDatePicker";
import ReactHookFormSelect from "../../../common/ReactHookFormSelect";
import ReactFormSwitch from "../../../common/ReactFormSwitch";
import RoeInsurableEarnings from "./RoeInsurableEarnings";
import RoeVacationPay from "./RoeVacationPay";
import RoeStatPay from "./RoeStatPay";
import RoeOtherMonies from "./RoeOtherMonies";
import RoeSpecialPayments from "./RoeSpecialPayments";
import InfoIconDialog from "../../../common/InfoIconDialog";
import RoeReasonForIssuing, {ReasonForIssuingType} from "./RoeReasonForIssuing";
import {generateRandomInt} from "../../../Utils/randomString";
import {getNumber} from "../../../Utils/stringMath";
import RoeTextField from "./RoeTextField";

type PropsType = {
    roeReportInputs: RoeReportParameterInput;
    reasonForIssuingInitialValues: ReasonForIssuingType;
    initialInsurableEarnings: InsurableEarnings;
    generateRoe: (
        values: RoeReportParameterInput,
        insurableEarnings: PayPeriod[],
        vacationPay: VacationPay | undefined | null,
        statPay: StatHolidayPayInput[] | undefined | null,
        otherMonies: OtherMoniesInput[] | undefined | null,
        specialPayments: SpecialPaymentsInput[] | undefined | null,
        reasonForIssuing: ReasonForIssuing
    ) => void;
    back: () => void;

}

function validateTotalInsurableHours(value: number | string) { // react-hook-form seems to be passing this in as a string even though the form value is an Int.
    if (!value) {
        return "Total insurable hours is required."
    }
    let valueAsNumber = getNumber(value);
    return (Number.isInteger(valueAsNumber) && valueAsNumber > 0) || "Service Canada only accepts a positive whole number without decimals for this field. If you have a decimal, please round up.";
}

function emptyStringForNull(value: string | undefined | null) {
    return !!value ? value : "";
}

function getVacationPaySummary(vacationPay: VacationPay | undefined | null) {
    return `${vacationPay?.vacationPayCode} ${emptyStringForNull(vacationPay?.startDate)} - ${emptyStringForNull(vacationPay?.endDate)} ${emptyStringForNull(vacationPay?.amount)}`
}

type AmountObject = { amount?: string | undefined | null };

function getTotalAmount(arrayOfObjects: AmountObject[] | undefined | null): number {
    if (!arrayOfObjects) {
        return 0;
    }
    return arrayOfObjects.reduce((accumulatedTotal: number, currentItem: AmountObject) => {
        const amountOrZero = !!currentItem?.amount ? getNumber(currentItem.amount) : 0;
        return accumulatedTotal + amountOrZero;
    }, 0);
}

function getStatPaySummary(statPay: Array<StatHolidayPayInput> | undefined | null) {
    const count = statPay?.length;
    return `${count} statutory holidays with total amount of ${getTotalAmount(statPay)}`;
}

function getOtherMoniesSummary(otherMonies: Array<OtherMoniesInput> | undefined | null) {
    const count = otherMonies?.length;
    return `${count} other monies with total amount of ${getTotalAmount(otherMonies)}`;
}

function getSpecialPaymentsSummary(specialPayments: Array<SpecialPaymentsInput> | undefined | null) {
    const count = specialPayments?.length;
    return `${count} special payments with total amount of ${getTotalAmount(specialPayments)}`;

}

function getReasonForIssuingSummary(reasonForIssuing: ReasonForIssuingType | undefined | null) {
    if (!reasonForIssuing || !reasonForIssuing.reasonCode || !reasonForIssuing.contactFirstName) {
        return "Required. Please select a reason for issuing this ROE";
    }
    return `${reasonForIssuing?.reasonCode}. ${reasonForIssuing?.contactFirstName}, ${reasonForIssuing?.contactLastName}, ${reasonForIssuing?.contactAreaCode} ${reasonForIssuing?.contactPhone} ext. ${reasonForIssuing?.contactExtension}`;
}

const PAYROLL_CA_ROE_LINK = "https://payroll.ca/getmedia/0721d1db-d5e5-44cd-bd24-854b08cb2438/Record-of-Employment-Guide-from-Service-Canada-ENG.PDF";

function getVacationPayWithoutTypeName(vacationPay: VacationPay | undefined | null): VacationPay | undefined {
    if (!vacationPay) {
        return undefined;
    }
    return {
        amount: vacationPay.amount,
        startDate: vacationPay.startDate,
        endDate: vacationPay.endDate,
        vacationPayCode: vacationPay.vacationPayCode,
    }
}


function renumberPayPeriods(payPeriodsToKeep: PayPeriod[]) {
    const sortedPayPeriods = payPeriodsToKeep.sort((a, b) => {
        if (!!a.endDate && !!b.endDate) {
            if (a.endDate < b.endDate) {
                return 1;
            }
        }
        if (!!a.endDate) {
            return -1;
        }
        return 1;
    });
    let payPeriodNumber = 1;
    return sortedPayPeriods.map(payPeriod => {
        return {
            ...payPeriod,
            number: payPeriodNumber++
        };
    });
}

function getNewPayPeriodId(prevPayPeriods: Array<PayPeriod>): string {
    const idsTaken = prevPayPeriods.map(payPeriod => Number(payPeriod.id));
    for (let i = 1; i <= 100; i++) {
        if (!idsTaken.includes(i)) {
            return i.toString();
        }
    }
    return generateRandomInt(101, 999).toString(); // should never hit this.
}


function getReasonForIssuingToSubmit(reasonForIssuing: ReasonForIssuingType, reasonCode: RoeSeparationCode): ReasonForIssuing {
    return {
        ...reasonForIssuing,
        reasonCode: reasonCode
    }
}

const RoeEmployeeEarningsForm = (props: PropsType) => {
    const {
        roeReportInputs,
        reasonForIssuingInitialValues,
        initialInsurableEarnings,
        generateRoe,
        back
    } = props;


    const [insurableEarnings, setInsurableEarnings] = useState<InsurableEarnings>(initialInsurableEarnings);
    const [vacationPay, setVacationPay] = useState<VacationPay | undefined | null>(getVacationPayWithoutTypeName(roeReportInputs.vacationPay));
    const [reasonForIssuing, setReasonForIssuing] = useState<ReasonForIssuingType | undefined | null>(reasonForIssuingInitialValues);
    const [statPay, setStatPay] = useState<Array<StatHolidayPayInput> | undefined | null>(roeReportInputs.statHolidayPay);
    const [otherMonies, setOtherMonies] = useState<Array<OtherMoniesInput> | undefined | null>(roeReportInputs.otherMonies);
    const [specialPayments, setSpecialPayments] = useState<Array<SpecialPaymentsInput> | undefined | null>(roeReportInputs.specialPayments);
    const [openReasonForIssuing, setOpenReasonForIssuing] = useState<boolean>(false);
    const [openVacationPay, setOpenVacationPay] = useState<boolean>(false);
    const [openStatPay, setOpenStatPay] = useState<boolean>(false);
    const [openOtherMonies, setOpenOtherMonies] = useState<boolean>(false);
    const [openSpecialPayments, setOpenSpecialPayments] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        formState: {errors},
        control
    } = useForm<RoeReportParameterInput>({
        defaultValues: roeReportInputs,
    });

    const onSubmit = (data: RoeReportParameterInput) => {
        let reasonCode = reasonForIssuing?.reasonCode;
        if (!!reasonForIssuing && !!reasonCode) {
            generateRoe(
                data,
                insurableEarnings.payPeriods,
                vacationPay,
                statPay,
                otherMonies,
                specialPayments,
                getReasonForIssuingToSubmit(reasonForIssuing, reasonCode)
            );
        } else {
            setOpenReasonForIssuing(true);
        }
    };


    function updatePayPeriod(payPeriod: PayPeriod) {
        if (!payPeriod.id || payPeriod.id === "0") {
            addPayPeriod(payPeriod);
            return;
        }
        setInsurableEarnings(prevState => {
            const prevPayPeriods = prevState.payPeriods;
            const payPeriodsToKeep = prevPayPeriods.filter(period => period.id !== payPeriod.id);
            payPeriodsToKeep.push(payPeriod);
            return {
                userMessage: prevState.userMessage,
                payPeriods: renumberPayPeriods(payPeriodsToKeep)
            };
        })
    }

    function deletePayPeriod(id: string) {
        setInsurableEarnings(prevState => {
            const prevPayPeriods = prevState.payPeriods;
            const payPeriodsToKeep = prevPayPeriods.filter(period => period.id !== id);
            return {
                userMessage: "",
                payPeriods: renumberPayPeriods(payPeriodsToKeep)
            };
        })
    }

    function addPayPeriod(payPeriod: PayPeriod) {
        setInsurableEarnings(prevState => {
            const payPeriods = [
                ...prevState.payPeriods,
                {
                    ...payPeriod,
                    id: getNewPayPeriodId(prevState.payPeriods)
                }
            ];
            return {
                userMessage: "",
                payPeriods: renumberPayPeriods(payPeriods)
            };
        })
    }

    const expectedRecallCodes = Object.values(RoeExpectedRecallCode);
    return <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container alignItems="center">
            <Grid container justifyContent={"start"} sx={{ml: .25}}>
                <Grid container>
                    <Box sx={{height: "100%", overflowY: "scroll", width: '100%', mx: 1, p: 3}}>
                        <Grid container sx={{mb: 2}}>
                            <Grid item xs={4}>
                                <ReactFormDatePicker<RoeReportParameterInput>
                                    fieldName={"firstDayWorked"}
                                    label={"First day worked"}
                                    control={control}
                                />
                            </Grid>
                            <Grid item xs={4} sx={{pl: 1}}>
                                <ReactFormDatePicker<RoeReportParameterInput>
                                    fieldName={"lastDayForWhichPaid"}
                                    label={"Last day for which paid"}
                                    control={control}
                                />
                            </Grid>
                            <Grid item xs={4} sx={{pl: 1}}>
                                <ReactFormDatePicker<RoeReportParameterInput>
                                    fieldName={"finalPayPeriodEndDate"}
                                    label={"Final pay period end date"}
                                    control={control}
                                />
                            </Grid>

                            <Grid item xs={4} sx={{pr: 5, mt: 3,}}>
                                <ReactHookFormSelect
                                    control={control}
                                    label={"Expected recall code"}
                                    nameOfFormField={`expectedRecall.expectedRecallCode`}
                                    requiredMessage={"Expected recall code is required"}
                                >
                                    {expectedRecallCodes.map(code => {
                                        return <MenuItem key={code} value={code}>
                                            {code}
                                        </MenuItem>
                                    })}
                                </ReactHookFormSelect>
                            </Grid>
                            <Grid item xs={4} sx={{mt: 3, pl: 1}}>
                                <ReactFormDatePicker<RoeReportParameterInput>
                                    fieldName={"expectedRecall.expectedRecallDate"}
                                    label={"Recall date"}
                                    control={control}
                                />
                            </Grid>
                            <Grid item xs={4} sx={{mt: 3, pl: 1, pr: 5}}>
                                <TextField
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {maxHeight: '50px', minHeight: '50px'},
                                        },
                                        '&& .MuiInputBase-input': {
                                            fontSize: '1.2rem'
                                        }
                                    }}
                                    fullWidth
                                    label={"Corporate payroll number (CRA business number)"}
                                    {...register("payrollAccountNumber", {required: "CRA business number is required."})}
                                    error={!!errors.payrollAccountNumber}
                                    helperText={errors.payrollAccountNumber?.message}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{borderColor: 'primary.secondary', mb: 2}}/>
                        </Grid>
                        <Grid container sx={{mb: 2, alignItems: 'center'}}>
                            <Grid item xs={3} sx={{mr: 2, mt: -1}}>
                                <RoeTextField
                                    fullWidth
                                    type={"number"}
                                    inputProps={{
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*'
                                    }}
                                    label={"Total insurable hours"}
                                    {...register("totalInsurableHours", {
                                        validate: validateTotalInsurableHours
                                    })}
                                    error={!!errors.totalInsurableHours}
                                    helperText={errors.totalInsurableHours?.message}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <ReactHookFormSelect<RoeReportParameterInput>
                                    nameOfFormField={"payPeriodType"}
                                    label={"Pay period type"}
                                    control={control}
                                >
                                    {Object.values(RoePayPeriodType).map((payPeriodType) => (
                                        <MenuItem key={payPeriodType} value={payPeriodType}>
                                            {payPeriodType}
                                        </MenuItem>
                                    ))}
                                </ReactHookFormSelect>
                            </Grid>

                        </Grid>
                        <Grid container direction="row" alignItems="center">
                            <Grid item xs={12} sx={{mr: 2}}>
                                <RoeInsurableEarnings
                                    roeInsurableEarnings={insurableEarnings}
                                    payPeriodDeleted={deletePayPeriod}
                                    updatePayPeriod={updatePayPeriod}
                                />
                            </Grid>

                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{borderColor: 'primary.secondary', mb: 2}}/>
                        </Grid>
                        <Grid container direction={"row"} alignItems="center" spacing={2} sx={{my: 1, ml: .25}}>
                            <Grid xs={4} container direction="row" alignItems="center">
                                <Grid item>
                                    <Button
                                        onClick={() => setOpenReasonForIssuing(true)}
                                    >
                                        Add reason for issuing ROE
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <InfoIconDialog
                                        title={"Reason for issuing (required)"}
                                        infoText={`Add the reason for issuing this ROE (Required)`}
                                        moreInfoLink={PAYROLL_CA_ROE_LINK}
                                    />
                                </Grid>
                            </Grid>

                            {openReasonForIssuing && (
                                <RoeReasonForIssuing
                                    reasonForIssuing={reasonForIssuing}
                                    updateReasonForIssuing={setReasonForIssuing}
                                    close={() => setOpenReasonForIssuing(false)}
                                />
                            )}
                            <Grid xs={8}>
                                <Typography>
                                    {getReasonForIssuingSummary(reasonForIssuing)}
                                </Typography>
                            </Grid>
                            <Grid xs={4} container direction="row" alignItems="center">
                                <Grid item>
                                    <Button
                                        onClick={() => setOpenVacationPay(true)}
                                    >
                                        Add vacation pay
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <InfoIconDialog
                                        title={"Vacation pay"}
                                        infoText={`Use this to add a separation payment for unpaid vacation pay. Only one payment is allowed. This should be included in the insurable earnings.`}
                                        moreInfoLink={PAYROLL_CA_ROE_LINK}
                                    />
                                </Grid>
                            </Grid>
                            {openVacationPay && (
                                <RoeVacationPay
                                    vacationPay={vacationPay}
                                    updateVacationPay={setVacationPay}
                                    close={() => setOpenVacationPay(false)}
                                />
                            )}
                            <Grid xs={8}>
                                <Typography>
                                    {getVacationPaySummary(vacationPay)}
                                </Typography>
                            </Grid>
                            <Grid xs={4} container direction="row" alignItems="center">
                                <Grid item>
                                    <Button
                                        onClick={() => setOpenStatPay(true)}
                                    >
                                        Add statutory holiday pay
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <InfoIconDialog
                                        title={"Statutory holiday pay"}
                                        infoText={`Use this to add any pay for statutory holidays that occurs after the last day for which paid. This amount should be in included in the insurable earnings reported.`}
                                        moreInfoLink={PAYROLL_CA_ROE_LINK}
                                    />
                                </Grid>
                            </Grid>
                            {openStatPay &&
                                <Grid item xs={12}>
                                    <RoeStatPay
                                        statPay={statPay}
                                        updateStatPay={setStatPay}
                                        close={() => setOpenStatPay(false)}
                                    />
                                </Grid>
                            }
                            <Grid xs={8}>
                                <Typography>
                                    {getStatPaySummary(statPay)}
                                </Typography>
                            </Grid>
                            <Grid xs={4} container direction="row" alignItems="center">
                                <Grid item>
                                    <Button
                                        onClick={() => setOpenOtherMonies(true)}
                                    >
                                        Add other monies paid
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <InfoIconDialog
                                        title={"Other monies"}
                                        infoText={`Use this to report any payments made because of separation which are not vacation pay or statutory holiday pay. Include insurable and non-insurable amounts.`}
                                        moreInfoLink={PAYROLL_CA_ROE_LINK}
                                    />
                                </Grid>
                            </Grid>
                            {openOtherMonies &&
                                <Grid item xs={12}>
                                    <RoeOtherMonies
                                        otherMonies={otherMonies}
                                        updateOtherMonies={setOtherMonies}
                                        close={() => setOpenOtherMonies(false)}
                                    />
                                </Grid>
                            }
                            <Grid xs={8}>
                                <Typography>
                                    {getOtherMoniesSummary(otherMonies)}
                                </Typography>
                            </Grid>
                            <Grid xs={4} container direction="row" alignItems="center">
                                <Grid item>
                                    <Button
                                        onClick={() => setOpenSpecialPayments(true)}
                                    >
                                        Add special payments
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <InfoIconDialog
                                        title={"Special payments"}
                                        infoText={`Use this to report any insurable sick leave, maternity leave, parental leave, compassionate care leave, or group wage-loss insurance payments from the employer, or if the employee is receiving any group wage-loss indemnity plan payments from a third party.`}
                                        moreInfoLink={PAYROLL_CA_ROE_LINK}
                                    />
                                </Grid>
                            </Grid>
                            {openSpecialPayments &&
                                <RoeSpecialPayments
                                    specialPayments={specialPayments}
                                    updateSpecialPayments={setSpecialPayments}
                                    close={() => setOpenSpecialPayments(false)}
                                />
                            }
                            <Grid xs={8}>
                                <Typography>
                                    {getSpecialPaymentsSummary(specialPayments)}
                                </Typography>
                            </Grid>
                        </Grid>


                        <Grid xs={12} container direction="row" alignItems="center" sx={{mt: 2}}>
                            <Grid item xs={6}>
                                {
                                    // todo: Kenny, please style this label.
                                }
                                <label htmlFor="comment">Comments</label>
                                <TextareaAutosize
                                    {...register("comment")}
                                    minRows={5}
                                    style={{
                                        minWidth: '100%',
                                        width: '100%',
                                        padding: 12
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <InfoIconDialog
                                    title={"Comments"}
                                    infoText={"Do not include any information that is already captured in other fields."}
                                />
                            </Grid>
                            <Grid xs={12} container direction="row" alignItems="center">
                                <Grid item>
                                    <ReactFormSwitch
                                        control={control}
                                        label={"Submit as draft"}
                                        fieldName={"submitDraft"}
                                    />
                                </Grid>
                                <Grid item>
                                    <InfoIconDialog
                                        title={"Service Canada draft option"}
                                        infoText={"Service Canada allows you to submit the ROE as a draft in case you'd like to review it on their site before submitting it. You need to select this option now and it will be included in the XML file that you upload to Service Canada."}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider sx={{borderColor: 'grey', borderWidth: '.5px', width: '100%', my: 2}}/>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Button
                                    variant={"contained"}
                                    style={{fontSize: '1.4rem'}}
                                    size={"large"}
                                    color={"warning"}
                                    onClick={back}
                                >
                                    Back
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant={"contained"}
                                    style={{fontSize: '1.4rem'}}
                                    size={"large"}
                                    type={"submit"}
                                >
                                    Generate ROE
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    </form>
}

export default RoeEmployeeEarningsForm;