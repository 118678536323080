import React, {forwardRef, useCallback, useEffect, useState} from "react";
import {Button, Grid, Typography} from "@mui/material";
import OneTwoPayTooltip from "../../common/OneTwoPayTooltip";
import EditDialog from "../../common/EditDialog";
import useAnonCookie from "../../security/useAnonCookie";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import VacationPayDisplay from "./VacationPayDisplay";
import {useGetVacationSummaryLazyQuery, VacationSummary} from "../../graphql/generated/graphql";
import VacationSummaryEdit from "./VacationSummaryEdit";
import useSystemNotices from "../../Utils/useSystemNotices";
import Box from "@mui/material/Box";


function getEmptyVacationSummary(): VacationSummary {
    return {
        vacationAccrued: "0",
        previousPeriodEarned: "0",
        currentPeriodEarned: "0",
        ytdEarned: "0",
        previousPeriodUsedPaid: "0",
        currentPeriodUsedPaid: "0",
        ytdUsedPaid: "0",
        payStubId: 0,
        id: 0,
    }
}


type PropsType = {
    payStubId: number | undefined;
    isEditable: boolean;
}


const VacationSummaryDisplayEdit = (props: PropsType) => {

    const {payStubId, isEditable} = props;
    const {getAnonUserId} = useAnonCookie();
    const [openVacationSummaryDialog, setOpenVacationSummaryDialog] = useState<boolean>(false);
    const [vacationSummary, setVacationSummary] = useState<VacationSummary>(getEmptyVacationSummary());
    const [error, setError] = useState<string>();
    const anonUserId = getAnonUserId();
    const {sendNotice} = useSystemNotices();

    const [
        getVacationSummary,
        {
            data: vacationSummaryData,
            loading: vacationSummaryLoading,
            error: vacationSummaryError
        }] = useGetVacationSummaryLazyQuery();


    const getVacationSummaryNow = useCallback((payStubId: number) => {
        if (vacationSummaryLoading || vacationSummaryError) {
            return;
        }
        if (!payStubId) {
            return;
        }
        getVacationSummary({
            variables: {
                anonUserId: anonUserId,
                payStubId: payStubId
            }
        })
            .then(result => {
                if (!result.data?.getVacationSummary) {
                    sendNotice(`unable to retrieve vacation summary for pay stub id ${payStubId}`);
                    setError("Unable to retrieve vacation summary. Please try again or contact support.");
                }
            })
            .catch(error => {
                sendNotice(`Unable to retrieve vacation summary. Error: ${error.message}`);
                setError("Unable to retrieve vacation summary. Please try again or contact support.");
            });
    }, [anonUserId, getVacationSummary, sendNotice, vacationSummaryError, vacationSummaryLoading]);

    useEffect(() => {
        if (vacationSummaryLoading || vacationSummaryError || error) {
            return;
        }
        if (payStubId) {
            getVacationSummaryNow(payStubId);
        }
    }, [error, getVacationSummaryNow, payStubId, vacationSummaryError, vacationSummaryLoading]);

    useEffect(() => {
        if (vacationSummaryData?.getVacationSummary) {
            setVacationSummary(vacationSummaryData.getVacationSummary);
        }
    }, [vacationSummaryData]);

    if (vacationSummaryLoading || error) {
        return <LoadingErrorDisplay
            loading={vacationSummaryLoading}
            stringError={error}
        />
    }

    if ((!vacationSummary || !vacationSummary.id) && !openVacationSummaryDialog) {
        return <OneTwoPayTooltip
            tipContent={"Add vacation pay summary"}
            TooltipTarget={forwardRef<HTMLButtonElement>((props, ref) => (
                <Button
                    {...props}
                    ref={ref}
                    variant={"text"}
                    onClick={() => setOpenVacationSummaryDialog(true)}
                >
                    Add vacation accrued
                </Button>
            ))}
        >
        </OneTwoPayTooltip>
    }


    return <Box maxWidth={500} sx={{backgroundColor: '#f4f4f4', mt: 2}}>
        <Grid container>
            <Grid item xs={12}>
                <Box display="flex" alignItems="center" sx={{pt: 2}}>
                    <Typography
                        variant={'h5'}
                        sx={{mr: 2, pl: 2}}
                    >
                        Vacation summary
                    </Typography>
                    {
                        isEditable &&
                        <EditDialog
                            dialogTitle={"Vacation pay summary"}
                            toolTipText={"Edit vacation pay summary"}
                            openDialog={openVacationSummaryDialog}
                            setOpenDialog={setOpenVacationSummaryDialog}
                        >
                            <VacationSummaryEdit
                                vacationSummary={vacationSummary}
                                close={() => setOpenVacationSummaryDialog(false)}
                            />
                        </EditDialog>
                    }
                </Box>
            </Grid>
            <Grid item xs={12}>
                <VacationPayDisplay
                    vacationPay={vacationSummary}
                />
            </Grid>
        </Grid>
    </Box>
}
export default VacationSummaryDisplayEdit;