import React, {useCallback, useContext, useEffect, useState} from "react";
import {
    Employee,
    PayStubBasic,
    useDeleteEmployeeMutation,
    useGetEmployeesLazyQuery,
    useGetPayStubsForEmployeeLazyQuery,
    useRestoreEmployeeMutation
} from "../graphql/generated/graphql";
import LoadingErrorDisplay from "../common/LoadingErrorDisplay";
import useAnonCookie from "../security/useAnonCookie";
import {DataGrid, GridCellParams, GridColDef} from "@mui/x-data-grid";
import ResponsiveEditButton from "../components/ResponsiveEditButton";
import {QuickSearchToolbar} from "../components/Report/EmployeesSelect";
import EditEmployee from "../components/Employee/EditEmployee";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid2,
    Switch
} from "@mui/material";
import useViewerStyle from "../components/PayStub/useViewerStyle";
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import SimplePayStubList from "../components/DataGrids/SimplePayStubList";
import {Link, useNavigate} from "react-router-dom";
import useSystemNotices from "../Utils/useSystemNotices";
import SuccessMessageDisplay from "../common/SuccessMessageDisplay";
import {getNumber} from "../Utils/stringMath";
import {assumedUserContext} from "../components/User/AssumedUserContext";
import {makeStyles} from "@mui/styles";
import OneTwoPayRotateScreenInstruction from "../common/OneTwoPayRotateScreenInstruction";
import CloseDialogButton from "../common/CloseDialogButton";
import CreateNextPayStubButton from "../components/PayStub/CreateNextPayStubButton";
import {RestoreFromTrash} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import OneTwoPayIconWithTooltip from "../common/OneTwoPayIconWithTooltip";


const useStyles = makeStyles(() => ({
    dialogContent: {
        padding: 3,
    },
    paper: {
        marginLeft: '10px',
        marginRight: '8px',
        margin: 0,
        width: '100%'
    },
}));

type EmployeeOptionRow = {
    id: number;
    name: string;
    address: string;
    deleted: boolean;
}

type MyColDef = GridColDef & {
    field: 'id' | 'name' | 'address' | 'showPayStubs' | 'actions',
}

interface MyGridCellParams extends GridCellParams {
    row: EmployeeOptionRow;
    id: any
}

function getColumnsForDataGrid(setEditEmployeeId: (employeeId: number) => void,
                               viewPayStubs: (employeeId: number) => void,
                               deleteEmployee: (employeeId: number) => void,
                               restoreEmployee: (employeeId: number) => void,
                               isScreenWide: boolean
): MyColDef[] {
    const nameColumn: MyColDef = {
        field: 'name',
        headerName: 'Name / ID',
        flex: 1,
        cellClassName: 'cell',
        renderCell: (params: MyGridCellParams) => {
            return (
                <Link
                    to={"/employees"}
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        viewPayStubs(params.id);
                    }}
                >
                    {params.row.name}
                </Link>
            );
        }
    };
    const addressColumn: MyColDef = {field: 'address', headerName: 'Address', flex: 1, cellClassName: 'cell'};
    const showPayStubsColumn: MyColDef = {
        field: 'showPayStubs',
        headerName: 'View pay stubs',
        flex: .5,
        cellClassName: 'cell',
        renderCell: (params: { id: any; }) => {
            return <ResponsiveEditButton
                buttonLabel={"View pay stubs"}
                editAction={event => {
                    event.stopPropagation();
                    viewPayStubs(params.id);
                }}
                editIcon={<AutoAwesomeMotionIcon/>}
            />;
        }
    };
    const actionsColumn: MyColDef = {
        field: 'actions',
        headerName: 'Actions',
        flex: .5,
        sortable: false,
        cellClassName: 'cell',
        renderCell: (params: MyGridCellParams) => {
            return (
                <div>
                    <ResponsiveEditButton
                        buttonLabel={"Edit Employee"}
                        editAction={event => {
                            event.stopPropagation();
                            setEditEmployeeId(params.id);
                        }}
                    />
                    {
                        params.row.deleted
                            ?
                            <OneTwoPayIconWithTooltip
                                tipContent={'Restore employee'}
                                ariaLabel={'Restore employee'}
                                onClick={() => restoreEmployee(getNumber(params.row.id))}
                                tooltipSx={{ml: 2}}
                                icon={<RestoreFromTrash sx={{fontSize: '2.5rem'}}/>}
                                color={'primary'}
                            />

                            :
                            <OneTwoPayIconWithTooltip
                                tipContent={'Delete employee'}
                                ariaLabel={'Delete employee'}
                                onClick={() => deleteEmployee(getNumber(params.row.id))}
                                tooltipSx={{ml: 2}}
                                icon={
                                    <DeleteIcon
                                        sx={{fontSize: '2.5rem'}}
                                    />
                                }
                                color={'error'}
                            />

                    }
                </div>
            )
        }
    };
    const columnDefinitions: MyColDef[] = [
        nameColumn,
        actionsColumn
    ];

    if (isScreenWide) {
        columnDefinitions.splice(1, 0, addressColumn, showPayStubsColumn);
    }
    return columnDefinitions;

}

type OptionRow = {
    id: string;
    name: string;
    address: string;
    deleted: boolean;
}


function getEmployeeDataForDataGrid(employeesData: Employee[], showDeletedEmployees: boolean): OptionRow[] {
    return employeesData
        .filter(employee => showDeletedEmployees || !employee.deleted)
        .map((employee: Employee) => {
                let optionRow: OptionRow = {
                    id: employee.id.toString(),
                    name: employee.detailedName,
                    address: employee.address1 || "",
                    deleted: employee.deleted
                };
                return optionRow
            }
        )
        .sort((option1: OptionRow, option2: OptionRow) => {
            let text2Upper = option2.name.toUpperCase();
            let text1Upper = option1.name.toUpperCase();
            return text1Upper < text2Upper ? -1 : text1Upper > text2Upper ? 1 : 0;
        });
}


function getEmployeeById(employeeId: number, employeesData: Employee[] | undefined) {
    if (!employeeId || !employeesData) {
        return undefined;
    }
    return employeesData.find(employee => {
        return Number(employee.id) === Number(employeeId);
    });
}

function sortPayStubs(employeePayStubs: Array<PayStubBasic>): Array<PayStubBasic> {

    const payStubsCopy = [...employeePayStubs];
    payStubsCopy.sort((option1, option2) => {
        return option1.payDate < option2.payDate ? -1 : option1.payDate > option2.payDate ? 1 : 0;
    });
    return payStubsCopy;

}

function getLastFinalizedPayStub(employeePayStubs: PayStubBasic[] | undefined) {
    if (!employeePayStubs) {
        return undefined;
    }
    const finalizedPayStubs = sortPayStubs(employeePayStubs.filter(payStub => payStub.finalized));
    if (!finalizedPayStubs || finalizedPayStubs.length === 0) {
        return undefined;
    }
    return finalizedPayStubs[finalizedPayStubs.length - 1];
}

const Employees = () => {

    const classes = useStyles();
    const {assumedUser} = useContext(assumedUserContext);
    const [editEmployeeId, setEditEmployeeId] = useState<number>();
    const [employeePayStubs, setEmployeePayStubs] = useState<Array<PayStubBasic>>();
    const [viewingEmployee, setViewingEmployee] = useState<Employee>();
    const [error, setError] = useState<string | null | undefined>();
    const [employees, setEmployees] = useState<Array<Employee>>();
    const [employeeUpdateSuccessMessage, setEmployeeUpdateSuccessMessage] = useState<string | null | undefined>();
    const [showDeletedEmployees, setShowDeletedEmployees] = useState<boolean>(false);

    const {isScreenWide} = useViewerStyle();
    const navigate = useNavigate();
    const {sendNotice} = useSystemNotices();
    const {getAnonUserId} = useAnonCookie();

    const [
        deleteEmployee,
        {
            loading: deleteEmployeeLoading,
            error: deleteEmployeeError
        }
    ] = useDeleteEmployeeMutation();

    const [
        restoreEmployee,
        {
            loading: restoreEmployeeLoading,
            error: restoreEmployeeError
        }
    ] = useRestoreEmployeeMutation();

    const anonUserId = getAnonUserId();
    const [
        getEmployees,
        {
            data: employeesData,
            loading: employeesLoading,
            error: employeesError
        }] = useGetEmployeesLazyQuery({
        variables: {
            anonUserId: anonUserId,
            userId: assumedUser.id
        },
        fetchPolicy: "no-cache"
    });

    const [
        getPayStubsForEmployee,
        {
            loading: payStubsForEmployeeLoading,
            error: payStubsForEmployeeError
        }] = useGetPayStubsForEmployeeLazyQuery();

    const getEmployeesNow = useCallback(() => {
        getEmployees()
            .then(() => {
                // do nothing. handled in useEffect.
            });
    }, [getEmployees]);

    useEffect(() => {
        if (!employeesData && !employeesLoading && !employeesError) {
            getEmployeesNow();
        }
    }, [getEmployeesNow, employeesData, employeesLoading, employeesError]);

    useEffect(() => {
        const employees = employeesData?.getEmployees?.employees;
        if (!!employees && employees.length > 0) {
            console.log("setting employees");
            setEmployees(employees);
            return;
        }
        if (!!employeesData && (!employees || employees?.length === 0)) {
            navigate("/pay-stub");
        }
    }, [employeesData, navigate]);

    const deleteEmployeeOnServer = useCallback((employeeId: number) => {
        deleteEmployee({
            variables: {
                employeeId: employeeId
            },
        })
            .then(result => {
                if (!result.data?.deleteEmployee.successful) {
                    sendNotice(`Error deleting employee: ${result.data?.deleteEmployee.message}`);
                    setError(result.data?.deleteEmployee.message);
                } else {
                    setEmployeeUpdateSuccessMessage("Employee deleted. This employee will no longer be included in reports or search results.");
                    getEmployeesNow();
                }
            })
            .catch(error => {
                sendNotice(`Error deleting employee: ${error.message}`);
                setError("Error deleting employee. Please try again or contact support.");
            });
    }, [deleteEmployee, sendNotice, getEmployeesNow]);

    const restoreEmployeeOnServer = useCallback((employeeId: number) => {
        restoreEmployee({
            variables: {
                employeeId: employeeId
            },
        })
            .then(result => {
                if (!result.data?.restoreEmployee.successful) {
                    sendNotice(`Error restoring employee: ${result.data?.restoreEmployee.message}`);
                    setError(result.data?.restoreEmployee.message);
                } else {
                    setEmployeeUpdateSuccessMessage("Employee restored. This employee will now be included in reports and search results.");
                    getEmployeesNow();
                }
            })
            .catch(error => {
                sendNotice(`Error restoring employee: ${error.message}`);
                setError("Error restoring employee. Please try again or contact support.");
            });
    }, [restoreEmployee, sendNotice, getEmployeesNow]);

    const loadPayStubsForEmployee = useCallback((employee: Employee) => {
        if (!employee) {
            sendNotice("Trying to view employee pay stubs but viewing employee not set.");
            return;
        }
        getPayStubsForEmployee({
            variables: {
                anonUserId: anonUserId,
                employeeId: employee.id
            },
            fetchPolicy: "no-cache"
        })
            .then(result => {
                const employeePayStubs = result.data?.getPayStubsForEmployee;
                if (!!employeePayStubs && employeePayStubs.length > 0) {
                    setEmployeePayStubs(sortPayStubs(employeePayStubs));
                } else if (employeePayStubs && employeePayStubs.length === 0) {
                    setError(`No pay stubs found for ${employee.detailedName}`)
                } else {
                    setError("Error retrieving pay stubs. Please try again or contact support.");
                }

            })
            .catch(error => setError(error.message));
    }, [anonUserId, getPayStubsForEmployee, sendNotice]);

    const openPayStubsForEmployee = useCallback((employeeId: number) => {
        setError(undefined);
        let employeeById = getEmployeeById(employeeId, employees);
        if (!employeeById) {
            sendNotice("Action required: getEmployeeById did not return an employee. That should not happen.");
            return;
        }
        setViewingEmployee(employeeById);
        loadPayStubsForEmployee(employeeById);
    }, [loadPayStubsForEmployee, employees, sendNotice]);

    if (employeesLoading || employeesError) {
        return <LoadingErrorDisplay
            loading={employeesLoading}
            apolloError={employeesError}
        />
    }

    const marginLeft = isScreenWide ? "20px" : "0px";

    function closeEmployeePayStubsDialog() {
        setViewingEmployee(undefined);
        setEmployeePayStubs(undefined);
    }

    const lastPayStub = getLastFinalizedPayStub(employeePayStubs);
    const lastPayStubId = getNumber(lastPayStub?.id);
    const payDate = lastPayStub?.payDate || "not set";

    return <div
        style={{display: "flex", minHeight: "500px", maxWidth: "1500px", marginTop: "10px", marginLeft: marginLeft}}>
        <div style={{margin: 'auto', width: '90%'}}>

            {
                !!employees &&
                <>
                    <OneTwoPayRotateScreenInstruction/>
                    <Grid2 container>
                        <Grid2 size={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        onChange={(event) => {
                                            setShowDeletedEmployees(event.target.checked);
                                        }}
                                        checked={showDeletedEmployees}
                                    />
                                }
                                label="Show Deleted Employees"
                            />
                        </Grid2>
                        <Grid2 size={12}>
                            <DataGrid
                                rows={getEmployeeDataForDataGrid(employees, showDeletedEmployees)}
                                columns={getColumnsForDataGrid(setEditEmployeeId, openPayStubsForEmployee, deleteEmployeeOnServer, restoreEmployeeOnServer, isScreenWide)}
                                slots={{toolbar: QuickSearchToolbar}}
                                pageSizeOptions={[5, 10, 25]}
                                initialState={{
                                    pagination: {paginationModel: {pageSize: 10}},
                                }}
                                sx={{
                                    '& .MuiDataGrid-cell': {
                                        fontSize: '1.25rem',
                                    },
                                    '& .MuiDataGrid-columnHeaders': {
                                        fontSize: '1.25rem',
                                    },
                                    '& .MuiDataGrid-footerContainer': {
                                        fontSize: '1.25rem',
                                    },
                                    '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows, & .MuiTablePagination-select, & .MuiTablePagination-actions': {
                                        fontSize: '1.25rem',
                                    },
                                }}
                            />
                        </Grid2>
                    </Grid2>
                </>
            }
            {
                !!editEmployeeId &&
                <Dialog
                    open={!!editEmployeeId}
                >
                    <DialogTitle>
                        <CloseDialogButton
                            close={() => setEditEmployeeId(undefined)}
                        />
                    </DialogTitle>
                    <DialogContent>
                        <EditEmployee
                            employeeId={editEmployeeId}
                            close={() => {
                                setEditEmployeeId(undefined);
                            }}
                            employeeAddedEdited={() => {
                                getEmployeesNow();
                                setEditEmployeeId(undefined);
                            }}
                        />
                    </DialogContent>

                </Dialog>
            }
            {
                !!employeePayStubs && employeePayStubs.length > 0 && viewingEmployee &&
                <Dialog
                    PaperProps={{className: classes.paper}}
                    fullWidth
                    maxWidth={'md'}
                    open={true}
                >
                    <DialogTitle>
                        <CloseDialogButton
                            close={closeEmployeePayStubsDialog}
                        />
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Grid2 container>
                            <Grid2 size={12}>
                                <SimplePayStubList
                                    rows={employeePayStubs}
                                    employeeName={viewingEmployee.detailedName}
                                    reload={() => loadPayStubsForEmployee(viewingEmployee)}
                                />
                            </Grid2>
                            <Grid2 size={12} sx={{mt: 2, mb: 1, ml: 1}}>
                                {
                                    !!lastPayStubId &&
                                    <CreateNextPayStubButton
                                        previousPayStubId={lastPayStubId}
                                        payDate={payDate}
                                    />
                                }
                            </Grid2>
                        </Grid2>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={closeEmployeePayStubsDialog}
                        >
                            Close
                        </Button>
                    </DialogActions>

                </Dialog>
            }
            {
                (!!error || payStubsForEmployeeLoading || payStubsForEmployeeError) &&
                <LoadingErrorDisplay
                    apolloError={payStubsForEmployeeError}
                    loading={payStubsForEmployeeLoading}
                    stringError={error}
                />
            }
            {
                (deleteEmployeeLoading || !!deleteEmployeeError || !!error || restoreEmployeeLoading || !!restoreEmployeeError) &&
                <LoadingErrorDisplay
                    loading={deleteEmployeeLoading || restoreEmployeeLoading}
                    apolloError={deleteEmployeeError || restoreEmployeeError}
                    stringError={error}
                />
            }
            {
                !!employeeUpdateSuccessMessage &&
                <SuccessMessageDisplay messages={employeeUpdateSuccessMessage}/>
            }

        </div>
    </div>

}

export default Employees;