import {makeStyles} from "@mui/styles";
import {IconButton, SxProps, Theme, Tooltip} from "@mui/material";
import React, {ReactNode} from "react";


const useStyles = makeStyles(() => ({
    customTooltip: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '1.15rem',
        border: '2px solid #2F61F6',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)'
    },
    arrow: {
        color: 'transparent',
    },
}));

type PropsType = {
    tipContent: string;
    onClick: () => void;
    icon: ReactNode;
    tooltipSx: SxProps<Theme> | undefined;
    ariaLabel: string;
    color: "primary" | "secondary" | "error" | "info" | "success" | "warning";
}

const OneTwoPayIconWithTooltip = (props: PropsType) => {

    const {tipContent, color, icon, onClick, tooltipSx, ariaLabel} = props;

    const classes = useStyles();

    return <Tooltip
        sx={tooltipSx}
        title={<span> {tipContent} </span>}
        placement="right-end"
        classes={{tooltip: classes.customTooltip, arrow: classes.arrow}}
        arrow
    >
        <IconButton
            onClick={onClick}
            aria-label={ariaLabel}
            color={color}
        >
            {icon}
        </IconButton>
    </Tooltip>


}

export default OneTwoPayIconWithTooltip;