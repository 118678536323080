import {
    DeleteIncomeItemMutation,
    IncomeItem,
    IncomeType,
    useDeleteIncomeItemMutation
} from "../../graphql/generated/graphql";
import React, {useCallback, useEffect, useState} from "react";
import IncomeItemDisplay from "./IncomeItemDisplay";
import EditDialog from "../../common/EditDialog";
import IncomeItemEdit, {ValidationResult} from "./IncomeItemEdit";
import {Grid2, Switch, Typography} from "@mui/material";
import {getNumber} from "../../Utils/stringMath";
import {FetchResult} from "@apollo/client";
import usePayStubIdManager from "./usePayStubIdManager";
import useAnonCookie from "../../security/useAnonCookie";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import ErrorDisplay from "../../common/ErrorDisplay";
import {getIncomeType} from "./incomeTypeFunctions";
import useCalculateVacationSummary from "./useCalculateVacationSummary";
import DeleteIcon from "@mui/icons-material/Delete";
import OneTwoPayIconWithTooltip from "../../common/OneTwoPayIconWithTooltip";

export type PropsType = {
    incomeItem: IncomeItem;
    incomeTypes: Array<IncomeType>;
    deleteItem: (id: string) => void;
    validateIncomeType: (incomeTypeId: string, incomeItemId: string) => ValidationResult;
    incomeItemUpdated: () => void;
    isEditable: boolean;
    employeeAccruingVacation: boolean;
};

function hasAmount(incomeItem: IncomeItem) {
    return !!incomeItem.amount && getNumber(incomeItem.amount) > 0;
}

function hasRate(incomeItem: IncomeItem) {
    return !!incomeItem.rate && getNumber(incomeItem.rate) > 0;
}

function hasUnits(incomeItem: IncomeItem) {
    return !!incomeItem.units && getNumber(incomeItem.units) > 0;
}

function isEmptyCurrentIncome(incomeItem: IncomeItem) {
    return !hasUnits(incomeItem) && !hasRate(incomeItem) && !hasAmount(incomeItem);
}

function isRateBasedItem(incomeItem: IncomeItem) {
    if (isEmptyCurrentIncome(incomeItem)) {
        return true;
    }
    return hasUnits(incomeItem) && hasRate(incomeItem);
}

function getIncomeItemEditTitle(incomeType: IncomeType | undefined, incomeItemId: string | undefined) {
    if (!incomeType) {
        return "Add income item";
    }
    const addOrEdit = !!incomeItemId ? "Edit" : "Add";
    const incomeOrBenefit = incomeType.isBenefit ? "benefit" : "income";
    return `${addOrEdit} ${incomeOrBenefit} item`;
}

function isVacationPay(incomeItem: IncomeItem, incomeTypes: Array<IncomeType>) {
    return getIncomeType(incomeItem.incomeTypeId, incomeTypes)?.taxTreatment.code === "VACATION_PAY";
}

const IncomeItemDisplayEdit: React.FC<PropsType> = (props) => {
    const {
        incomeItem,
        incomeTypes,
        deleteItem,
        validateIncomeType,
        incomeItemUpdated,
        isEditable,
        employeeAccruingVacation
    } = props;

    const [selectedIncomeType, setSelectedIncomeType] = useState(getIncomeType(incomeItem.incomeTypeId, incomeTypes));
    const [editIncomeItem, setEditIncomeItem] = useState<boolean>(false);
    const [isRateBased, setIsRateBased] = React.useState<boolean>(isRateBasedItem(incomeItem));
    const [error, setError] = useState<string>();

    const {getAnonUserId} = useAnonCookie();
    const {getPayStubId} = usePayStubIdManager();
    const {recalculateVacationSummary} = useCalculateVacationSummary();

    const [
        deleteIncomeItemOnServer,
        {
            error: deleteIncomeItemError,
            loading: deleteIncomeItemLoading
        }
    ] = useDeleteIncomeItemMutation();


    const handleChange = () => {
        setIsRateBased(!isRateBased);
    };

    useEffect(() => {
        setIsRateBased(isRateBasedItem(incomeItem));
    }, [incomeItem]);

    useEffect(() => {
        if (isEmptyCurrentIncome(incomeItem) && !incomeItem.priorPeriodAmount) {
            setEditIncomeItem(true);
        }
    }, [setEditIncomeItem, incomeItem]);

    const cancelEditIncomeItem = useCallback(() => {
        if (!incomeItem.id) {
            deleteItem(incomeItem.id);
        }
    }, [incomeItem, deleteItem]);

    const deleteIncomeItem = () => {
        if (!incomeItem.id) {
            deleteItem(incomeItem.id);
            setEditIncomeItem(false);
            return;
        }

        const payStubId = getPayStubId();
        if (!payStubId) {
            return;
        }

        deleteIncomeItemOnServer({
            variables: {
                currentPeriodId: incomeItem.currentPeriodItemId,
                priorPeriodId: incomeItem.priorPeriodItemId,
                anonUserId: getAnonUserId()
            },
        })
            .then((result: FetchResult<DeleteIncomeItemMutation>) => {
                if (!!result.data?.deleteIncomeItem.successful) {
                    if (employeeAccruingVacation &&
                        (incomeItem.includeInVacationAccrual || isVacationPay(incomeItem, incomeTypes))) {
                        recalculateVacationSummary();
                    }
                    setEditIncomeItem(false);
                    incomeItemUpdated();
                    return;
                } else {
                    setError("Delete failed. Please try again or contact support.");
                }
            });
    }


    function getEditIncomeDialogTitle() {
        return (
            <Grid2 container alignItems="center" justifyContent="space-between" sx={{mt: 4}}>
                <Grid2>
                    <Typography
                        variant="h3"
                        sx={{
                            ml: 1.50,
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                        {getIncomeItemEditTitle(selectedIncomeType, incomeItem.id)}
                    </Typography>
                </Grid2>
                <Grid2>
                    {deleteIncomeItemLoading
                        ? <LoadingErrorDisplay
                            loading={deleteIncomeItemLoading}
                        />
                        : <OneTwoPayIconWithTooltip
                            tipContent={'Delete income item'}
                            ariaLabel={'Delete income item'}
                            onClick={deleteIncomeItem}
                            tooltipSx={{ml: 2}}
                            icon={<DeleteIcon sx={{fontSize: '2.5rem'}}/>}
                            color={'error'}
                        />
                    }
                    {
                        (deleteIncomeItemError || error) &&
                        <ErrorDisplay
                            stringError={error}
                            apolloError={deleteIncomeItemError}
                        />
                    }
                </Grid2>
                <Grid2 size={12}>
                    <Grid2 container alignItems="center" spacing={1}
                           sx={{ml: 0.75}}
                    >
                        <Grid2>
                            <Typography variant={"body1"} color={isRateBased ? "textPrimary" : "primary"}>
                                Fixed
                            </Typography>
                        </Grid2>
                        <Grid2>
                            <Switch
                                checked={isRateBased}
                                onChange={handleChange}
                                sx={{
                                    "& .Mui-checked": {color: '#0D47A1'},
                                    "& .MuiSwitch-switchBase:not(.Mui-checked)": {color: '#64B5F6'}
                                }}
                            />
                        </Grid2>
                        <Grid2>
                            <Typography
                                variant={isRateBased ? "body1" : "body1"}
                                color={isRateBased ? "primary" : "textPrimary"}
                            >
                                {selectedIncomeType?.rateLabel}
                            </Typography>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Grid2>
        );
    }


    return <IncomeItemDisplay
        incomeItem={incomeItem}
        isRateBased={isRateBased}
        incomeType={selectedIncomeType}
        editIconDialog={
            isEditable && !!selectedIncomeType
                ? <EditDialog
                    dialogTitle={getEditIncomeDialogTitle()}
                    toolTipText={"Edit item"}
                    openDialog={editIncomeItem}
                    setOpenDialog={setEditIncomeItem}
                    triggerCancelEditAction={cancelEditIncomeItem}
                >
                    <IncomeItemEdit
                        isRateBased={isRateBased}
                        incomeItem={incomeItem}
                        incomeTypes={incomeTypes}
                        close={() => {
                            cancelEditIncomeItem();
                            setEditIncomeItem(false);
                        }}
                        deleteItem={deleteItem}
                        validateIncomeType={validateIncomeType}
                        incomeItemUpdated={incomeItemUpdated}
                        setIncomeType={setSelectedIncomeType}
                        incomeType={selectedIncomeType}
                        employeeAccruingVacation={employeeAccruingVacation}
                    />
                </EditDialog>
                : <></>
        }
    />
};

export default IncomeItemDisplayEdit;
