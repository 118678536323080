import React, {useEffect, useState} from "react";
import {
    Deduction,
    DeductionItemInput,
    DeductionType,
    SaveDeductionMutation,
    useSaveDeductionMutation,
} from "../../graphql/generated/graphql";
import {useForm} from "react-hook-form";
import {Button, Card, CardContent, Grid, Typography} from "@mui/material";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import useAnonCookie from "../../security/useAnonCookie";
import usePayStubIdManager from "./usePayStubIdManager";
import {add, getNumber} from "../../Utils/stringMath";
import {FetchResult} from "@apollo/client";
import CustomDeductionTypeSelectEdit from "./DeductionTypeSelectEdit";
import useGetDeductionTypeInfo from "./useGetDeductionTypeInfo";
import OneTwoPayNumericTextField from "../../common/OneTwoPayNumericTextField";

export type ValidationResult = {
    valid: boolean;
    message?: string;
}

type PropsType = {
    deductionItem: Deduction;
    deductionTypes: Array<DeductionType>;
    close: () => void;
    deleteItem: (is: string) => void;
    validateDeductionType: (deductionTypeId: number, deductionItemId: string) => ValidationResult;
    deductionItemUpdated: () => void;
}


function calculateYtdAmount(values: Deduction) {
    let currentAmount = getNumber(values.amount);
    if (!currentAmount) {
        currentAmount = 0;
    }
    let priorPeriodAmount = getNumber(values.priorPeriodAmount);
    return currentAmount + priorPeriodAmount;
}

function getDeductionItemInput(data: Deduction, deductionTypeId: number, payStubId: number, currentPeriodId: number | undefined | null, priorPeriodId: number | undefined | null): DeductionItemInput {
    return {
        currentPeriodId: currentPeriodId,
        priorPeriodId: priorPeriodId,
        deductionTypeId: deductionTypeId,
        priorPeriodAmount: data.priorPeriodAmount,
        amount: data.amount,
        ytdAmount: add(data.amount, data.priorPeriodAmount),
        payStubId: payStubId
    }
}

const DeductionItemEdit = (props: PropsType) => {
    const {
        deductionItem,
        deductionTypes,
        close,
        validateDeductionType,
        deductionItemUpdated,
    } = props;

    const [ytdAmount, setYtdAmount] = useState(calculateYtdAmount(deductionItem));
    const [error, setError] = useState<string>();
    const {getDeductionType} = useGetDeductionTypeInfo();
    const [selectedDeductionType, setSelectedDeductionType] = useState(getDeductionType(deductionItem.deductionTypeId, deductionTypes));
    const {getAnonUserId} = useAnonCookie();

    const {
        getPayStubId,
        createNewPayStub,
        createNewPayStubLoading,
        createNewPayStubError
    } = usePayStubIdManager();


    const {
        handleSubmit,
        register,
        getValues,
        formState: {errors},
        watch,

    } = useForm<Deduction>(
        {
            defaultValues: deductionItem
        }
    );

    const updateCustomDeductionType = (deductionType: DeductionType) => {
        setError(undefined);
        setSelectedDeductionType(deductionType);
    }

    const [
        saveDeductionItem,
        {
            error: saveDeductionItemError,
            loading: saveDeductionItemLoading
        }
    ] = useSaveDeductionMutation();


    const updateDeductionItem = async (data: Deduction) => {
        if (saveDeductionItemLoading || createNewPayStubLoading) {
            return;
        }
        const result = validateDeductionType(selectedDeductionType.id, data.id);
        if (!result.valid && !!result.message) {
            setError(result.message);
            return;
        }

        let payStubId = getPayStubId();
        if (!payStubId) {
            payStubId = await createNewPayStub();
        }
        if (!payStubId) {
            setError("Unable to initiate new pay stub. Please try again or contact support.");
            return;
        }
        const deductionItemInput: DeductionItemInput = getDeductionItemInput(data, selectedDeductionType.id, payStubId, deductionItem.currentPeriodId, deductionItem.priorPeriodId);

        saveDeductionItem({
            variables: {
                input: deductionItemInput,
                anonUserId: getAnonUserId(),
                payStubId: payStubId
            },
        })
            .then((result: FetchResult<SaveDeductionMutation>) => {
                if (result.data && result.data.saveDeduction) {
                    close();
                    deductionItemUpdated();
                } else {
                    setError("Error saving deduction item. Please try again or contact support.");
                }
            });
    }

    const watchedValues = watch(['amount', 'priorPeriodAmount']);
    useEffect(() => {
        let values = getValues();
        setYtdAmount(calculateYtdAmount(values));
    }, [setYtdAmount, watchedValues, getValues]);

    return <>
        {
            !!deductionTypes && deductionTypes.length > 0 &&
            <Grid item xs={10} sx={{ml: 2}}>
                <CustomDeductionTypeSelectEdit
                    deductionType={selectedDeductionType}
                    setCustomDeductionType={updateCustomDeductionType}
                    deductionTypes={deductionTypes}
                    currentPayStubId={getPayStubId()}
                    close={close}
                />
            </Grid>
        }
        <form noValidate onSubmit={handleSubmit(updateDeductionItem)}>

            <Grid item xs={12} sx={{mt: 4}}>
                {(
                    <Grid item xs={4}>
                        <OneTwoPayNumericTextField
                            label={"Amount"}
                            amount={getValues("amount")}
                            {...register("amount")}
                            error={!!errors.amount}
                            errorText={errors.amount?.message}
                            variant={"outlined"}
                            sx={{
                                ml: 2
                            }}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container direction="row" sx={{mb: 4}}>
                <Grid item xs={5}>
                    <OneTwoPayNumericTextField
                        label={"Prior periods amount"}
                        amount={getValues("priorPeriodAmount")}
                        {...register("priorPeriodAmount")}
                        error={!!errors.priorPeriodAmount}
                        errorText={errors.priorPeriodAmount?.message}
                        variant={"outlined"}
                        sx={{
                            ml: 2,
                            mt: 4
                        }}
                    />
                </Grid>
                <Grid item xs={5}
                      sx={{mt: 2, ml: 4}}
                >
                    <Card sx={{boxShadow: '0 10px 20px rgba(0, 0, 0, 0.5)', borderRadius: '8px'}}>
                        <CardContent>
                            <Typography variant="subtitle1" color="text.secondary">YTD Amount</Typography>
                            <Typography>
                                {ytdAmount}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Grid item xs={5}>
                    <Button
                        variant={"contained"}
                        style={{fontSize: '1.2rem'}}
                        fullWidth
                        color={"error"}
                        onClick={close}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={5}>
                    <Button
                        variant={"contained"}
                        fullWidth
                        disabled={saveDeductionItemLoading || createNewPayStubLoading}
                        type={"submit"}
                        style={{fontSize: '1.2rem'}}
                    >
                        Save deduction
                    </Button>
                </Grid>
            </Grid>
        </form>
        {(saveDeductionItemLoading ||
                !!saveDeductionItemError ||
                createNewPayStubLoading ||
                !!createNewPayStubError ||
                !!error
            ) &&
            <LoadingErrorDisplay
                loading={saveDeductionItemLoading || createNewPayStubLoading}
                apolloError={saveDeductionItemError || createNewPayStubError}
                stringError={error}
            />
        }
    </>
}
export default DeductionItemEdit;