import {
    Deduction,
    DeductionType,
    DeleteDeductionMutation,
    useDeleteDeductionMutation,
} from "../../graphql/generated/graphql";
import React, {useCallback, useEffect, useState} from "react";

import EditDialog from "../../common/EditDialog";
import {Grid2, Typography} from "@mui/material";
import {getNumber} from "../../Utils/stringMath";
import {FetchResult} from "@apollo/client";
import usePayStubIdManager from "./usePayStubIdManager";
import useAnonCookie from "../../security/useAnonCookie";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import ErrorDisplay from "../../common/ErrorDisplay";
import {ValidationResult} from "./IncomeItemEdit";
import DeductionDisplay from "./DeductionDisplay";
import DeductionItemEdit from "./DeductionEdit";
import useGetDeductionTypeInfo from "./useGetDeductionTypeInfo";
import DeleteIcon from "@mui/icons-material/Delete";
import OneTwoPayIconWithTooltip from "../../common/OneTwoPayIconWithTooltip";

export type PropsType = {
    deductionItem: Deduction;
    deductionTypes: Array<DeductionType>;
    deleteItem: (id: string) => void;
    validateDeductionType: (deductionTypeId: number, deductionItemId: string) => ValidationResult;
    deductionUpdated: () => void;
    isEditable: boolean;
};

function hasAmount(deductionItem: Deduction) {
    return !!deductionItem.amount && getNumber(deductionItem.amount) > 0;
}

function hasPriorAmount(deductionItem: Deduction) {
    return !!deductionItem.priorPeriodAmount && getNumber(deductionItem.priorPeriodAmount) > 0;
}

function isEmptyDeductionItem(deductionItem: Deduction) {
    return !hasAmount(deductionItem) && !hasPriorAmount(deductionItem) && !deductionItem.id;
}


function getDeductionItemEditTitle(deductionType: DeductionType | undefined, deductionItemId: string | undefined) {
    if (!deductionType) {
        return "Add deduction";
    }
    const addOrEdit = !!deductionItemId ? "Edit" : "Add";

    return `${addOrEdit} deduction`;
}

const DeductionItemDisplayEdit: React.FC<PropsType> = (props) => {
    const {deductionItem, deductionTypes, deleteItem, validateDeductionType, deductionUpdated, isEditable} = props;
    const {getDeductionType} = useGetDeductionTypeInfo();
    const [editDeductionItem, setEditDeductionItem] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    const {getAnonUserId} = useAnonCookie();
    const {getPayStubId} = usePayStubIdManager();


    const [
        deleteDeductionItemOnServer,
        {
            error: deleteDeductionItemError,
            loading: deleteDeductionItemLoading
        }
    ] = useDeleteDeductionMutation();


    useEffect(() => {
        if (isEmptyDeductionItem(deductionItem)) {
            setEditDeductionItem(true);
        }
    }, [setEditDeductionItem, deductionItem]);

    const cancelEditDeductionItem = useCallback(() => {
        if (!deductionItem.id) {
            deleteItem(deductionItem.id);
        }
    }, [deductionItem, deleteItem]);

    const deleteDeductionItem = () => {
        if (!deductionItem.id) {
            deleteItem(deductionItem.id);
            setEditDeductionItem(false);
            return;
        }

        const payStubId = getPayStubId();
        if (!payStubId) {
            return;
        }

        deleteDeductionItemOnServer({
            variables: {
                currentPeriodId: deductionItem.currentPeriodId,
                priorPeriodId: deductionItem.priorPeriodId,
                anonUserId: getAnonUserId()
            },
        })
            .then((result: FetchResult<DeleteDeductionMutation>) => {
                if (!!result.data?.deleteDeduction.successful) {
                    setEditDeductionItem(false);

                    deductionUpdated();
                    return;
                } else {
                    setError("Delete failed. Please try again or contact support.");
                }
            });
    }


    function getEditDeductionDialogTitle() {
        return (
            <Grid2 container alignItems="center" justifyContent="space-between" sx={{mt: 2}}>
                <Grid2>
                    <Typography variant="h3" sx={{ml: 2, display: 'flex', alignItems: 'center'}}>
                        {getDeductionItemEditTitle(deductionType, deductionItem.id)}
                    </Typography>
                </Grid2>
                <Grid2>
                    {deleteDeductionItemLoading
                        ? <LoadingErrorDisplay
                            loading={deleteDeductionItemLoading}
                        />
                        : <OneTwoPayIconWithTooltip
                            tipContent={'Delete deduction item'}
                            ariaLabel={'Delete deduction item'}
                            onClick={deleteDeductionItem}
                            tooltipSx={{ml: 2}}
                            icon={<DeleteIcon sx={{fontSize: '2.5rem'}}/>}
                            color={'error'}
                        />
                    }
                    {
                        (deleteDeductionItemError || error) &&
                        <ErrorDisplay
                            stringError={error}
                            apolloError={deleteDeductionItemError}
                        />
                    }
                </Grid2>

            </Grid2>
        );
    }

    const deductionType = getDeductionType(deductionItem.deductionTypeId, deductionTypes);
    return <DeductionDisplay
        deduction={deductionItem}
        deductionType={deductionType}
        editIconDialog={
            isEditable
                ? <EditDialog
                    dialogTitle={getEditDeductionDialogTitle()}
                    toolTipText={"Edit item"}
                    openDialog={editDeductionItem}
                    setOpenDialog={setEditDeductionItem}
                    triggerCancelEditAction={cancelEditDeductionItem}
                >
                    <DeductionItemEdit
                        deductionItem={deductionItem}
                        deductionTypes={deductionTypes}
                        close={() => {
                            cancelEditDeductionItem();
                            setEditDeductionItem(false);
                        }}
                        deleteItem={deleteItem}
                        validateDeductionType={validateDeductionType}
                        deductionItemUpdated={deductionUpdated}
                    />
                </EditDialog>
                : <></>
        }
    />
};

export default DeductionItemDisplayEdit;
