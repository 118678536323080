import React, {useCallback, useState} from 'react';
import {DataGrid, GridCellParams, GridColDef, GridRowsProp} from '@mui/x-data-grid';
import {Typography} from "@mui/material";
import {QuickSearchToolbar} from "../Report/EmployeesSelect";
import ResponsiveEditButton from "../ResponsiveEditButton";
import {Link, useNavigate} from "react-router-dom";
import {useDeletePayStubMutation} from "../../graphql/generated/graphql";
import useAnonCookie from "../../security/useAnonCookie";
import useSystemNotices from "../../Utils/useSystemNotices";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import SuccessMessageDisplay from "../../common/SuccessMessageDisplay";
import DeleteConfirmDialog from "../../common/DeleteConfirmDialog";
import useViewerStyle from "../PayStub/useViewerStyle";
import {makeStyles} from "@mui/styles";
import theme from "../../theme";
import OneTwoPayRotateScreenInstruction from "../../common/OneTwoPayRotateScreenInstruction";
import DeleteIcon from "@mui/icons-material/Delete";
import OneTwoPayIconWithTooltip from "../../common/OneTwoPayIconWithTooltip";
import {getNumber} from "../../Utils/stringMath";

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        margin: '0 auto',
        padding: '0',
        '& .MuiDataGrid-root': {
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[4],
            '& .MuiDataGrid-columnHeader, .MuiDataGrid-overlay': {
                backgroundColor: '#e1f5fe',
                borderBottom: `1px solid ${theme.palette.divider}`,
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                    fontSize: '.9rem',
                },
            },
            '& .MuiDataGrid-row': {
                '&:nth-of-type(odd)': {
                    backgroundColor: '#f5f5f5',
                },
                '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                },
                '& .MuiDataGrid-cell': {
                    fontSize: '1.1rem',
                },
            },
            '& .MuiDataGrid-cell': {
                display: 'flex',
                alignItems: 'center',
                paddingLeft: 4,
                paddingRight: 2,
            },
        },
    },
}));

type PayStubOptionRow = {
    id: number;
    payDate: string;
    payPeriod: string;
    finalized: boolean;
}

type MyColDef = GridColDef & {
    field: 'id' | 'payPeriod' | 'payDate' | 'finalized' | 'actions',
}

interface MyGridCellParams extends GridCellParams {
    row: PayStubOptionRow;
}

const getColumns = (openPayStub: (payStubId: number) => void,
                    deletePayStub: (payStubId: number) => void,
                    isScreenWide: boolean
): MyColDef[] => {
    const idColumn: MyColDef = {
        field: 'id',
        headerName: isScreenWide ? 'Pay stub ID' : 'ID',
        flex: .7,
        maxWidth: isScreenWide ? 125 : 80,
        cellClassName: 'cell',
    };
    const payPeriodColumn: MyColDef = {
        field: 'payPeriod',
        headerName: 'Pay period',
        maxWidth: 300,
        flex: 1,
        cellClassName: 'cell',
        renderCell: (params: MyGridCellParams) => {
            return (
                <Link
                    style={{fontSize: '1.1rem'}}
                    to={"/employees"} // overridden by onClick
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        openPayStub(params.row.id);
                    }}
                >
                    {params.row.payPeriod}
                </Link>
            );
        }
    };
    const payDateColumn: MyColDef = {
        field: 'payDate',
        headerName: 'Pay date',
        flex: 1,
        cellClassName: 'cell'
    };
    const finalizedColumn: MyColDef = {
        field: 'finalized',
        headerName: 'Final',
        maxWidth: 90,
        minWidth: 70,
        flex: 1,
        cellClassName: 'cell',
        renderCell: (params: MyGridCellParams) => {
            return <Typography
                style={{paddingTop: '15px', paddingLeft: '5px'}}
            >
                {params.row.finalized ? "Yes" : "No"}
            </Typography>;
        }
    };
    const actionsColumn: MyColDef = {
        field: 'actions',
        headerName: isScreenWide ? 'Actions' : '',
        flex: .8,
        maxWidth: isScreenWide ? 200 : 50,
        cellClassName: 'cell',
        renderCell: (params: MyGridCellParams) => {
            return (
                <>
                    {
                        isScreenWide &&
                        <ResponsiveEditButton
                            buttonLabel={"View pay stub"}
                            editAction={event => {
                                event.stopPropagation();
                                openPayStub(params.row.id);
                            }}
                            sx={{marginRight: '2rem'}}
                        />
                    }

                    <OneTwoPayIconWithTooltip
                        tipContent={'Delete pay stub'}
                        ariaLabel={'Delete pay stub'}
                        onClick={() => deletePayStub(params.row.id)}
                        tooltipSx={{marginLeft: 0}}
                        icon={<DeleteIcon sx={{fontSize: '2.5rem'}}/>}
                        color={'error'}
                    />
                </>

            )
        }
    };

    const columns = [
        idColumn,
        payPeriodColumn,
        actionsColumn,
    ];

    if (isScreenWide) {
        columns.splice(2, 0, payDateColumn, finalizedColumn);
    }
    return columns;
}


interface SimplePayStubsListProps {
    rows: GridRowsProp,
    employeeName: string,
    reload: () => void;
}


const SimplePayStubList = (props: SimplePayStubsListProps) => {
    const classes = useStyles();
    const {rows, employeeName, reload} = props;
    const navigate = useNavigate();
    const {getAnonUserId} = useAnonCookie();
    const {sendNotice} = useSystemNotices();
    const [error, setError] = useState<string | null | undefined>();
    const [deleteSuccessMessage, setDeleteSuccessMessage] = useState<string | null | undefined>();
    const [deletePayStubId, setDeletePayStubId] = useState<number>();
    const {isScreenWide} = useViewerStyle();

    const [
        deletePayStub,
        {
            loading: deleteLoading,
            error: deleteError
        }
    ] = useDeletePayStubMutation();

    const goToPayStub = useCallback((payStubId: number) => {
        navigate(`/pay-stub/${payStubId}`);
    }, [navigate]);

    const deletePayStubOnServer = useCallback((payStubId: number) => {
        deletePayStub({
            variables: {
                payStubId: getNumber(payStubId),
                anonUserId: getAnonUserId()
            },
        })
            .then(result => {
                if (!result.data?.deletePayStub.successful) {
                    sendNotice(`Error deleting pay stub: ${result.data?.deletePayStub.message}`);
                    setError(result.data?.deletePayStub.message);
                } else {
                    setDeleteSuccessMessage("Pay stub deleted. It will no longer be included in reports or search results.");
                    reload();
                }
                setDeletePayStubId(undefined);
            })
            .catch(error => {
                sendNotice(`Error deleting pay stub: ${error.message}`);
                setError("Error deleting pay stub. Please try again or contact support.");
                setDeletePayStubId(undefined);
            });
    }, [deletePayStub, getAnonUserId, sendNotice, reload]);


    return (
        <div className={classes.root}>
            <Typography variant="h1" align="center" sx={{fontSize: '2.4rem', mb: 4, mt: 4}}>
                {employeeName}
            </Typography>
            <OneTwoPayRotateScreenInstruction/>
            <DataGrid
                slots={{toolbar: QuickSearchToolbar}}
                rows={rows}
                columns={getColumns(goToPayStub, setDeletePayStubId, isScreenWide)}
                autoHeight
            />
            {
                (deleteLoading || !!deleteError || !!error) &&
                <LoadingErrorDisplay
                    loading={deleteLoading}
                    apolloError={deleteError}
                    stringError={error}
                />
            }
            {
                !!deleteSuccessMessage &&
                <SuccessMessageDisplay messages={deleteSuccessMessage}/>
            }
            {
                !!deletePayStubId &&
                <DeleteConfirmDialog
                    itemDescription={`pay stub ${deletePayStubId}`}
                    confirmAction={() => deletePayStubOnServer(deletePayStubId)}
                    cancelAction={() => setDeletePayStubId(undefined)}
                />
            }
        </div>
    );
};

export default SimplePayStubList;