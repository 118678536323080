import {useCreateEmptyPayStubMutation} from "../../graphql/generated/graphql";
import {ApolloError} from "@apollo/client";
import {useNavigate, useParams} from "react-router-dom";
import useAnonCookie from "../../security/useAnonCookie";
import {useCallback, useContext} from "react";
import {assumedUserContext} from "../User/AssumedUserContext";
import {getNumber} from "../../Utils/stringMath";


type ReturnType = {
    createNewPayStubLoading: boolean;
    createNewPayStubError: ApolloError | undefined;
    getPayStubId: () => number | undefined;
    createNewPayStub: () => Promise<number | undefined>
}

type ParamsType = {
    paramsPayStubId: string;
}


const usePayStubIdManager = (): ReturnType => {

    const {paramsPayStubId} = useParams<ParamsType>();

    const navigate = useNavigate();
    const {getAnonUserId} = useAnonCookie();
    const {assumedUser} = useContext(assumedUserContext);


    const [
        createEmptyPayStub,
        {
            error: createEmptyStubError,
            loading: createEmptyStubLoading
        }
    ] = useCreateEmptyPayStubMutation({
        variables: {
            anonUserId: getAnonUserId(),
            userId: assumedUser.id
        }
    });

    const getPayStubId = useCallback((): number | undefined => {
        if (!!paramsPayStubId) {
            return getNumber(paramsPayStubId);
        }
        return undefined;
    }, [paramsPayStubId]);

    const createNewPayStub = useCallback(async (): Promise<number> => {
        return createEmptyPayStub()
            .then(result => {
                let payStubId = result.data?.createEmptyPayStub;
                if (!payStubId) {
                    throw new Error("Unexpected error. Failed to create pay stub. Please try again or contact support.");
                }
                navigate(`/pay-stub/${payStubId}`)
                return payStubId;
            });
    }, [createEmptyPayStub, navigate]);

    return {
        getPayStubId: getPayStubId,
        createNewPayStub: createNewPayStub,
        createNewPayStubLoading: createEmptyStubLoading,
        createNewPayStubError: createEmptyStubError,
    }
}

export default usePayStubIdManager;